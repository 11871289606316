import { createContext } from "react";

export const PortalContext = createContext({
  deviceMode : "windows",
  setDeviceMode : () => {},
  ipadOnboarded: "checking",
  setIpadOnboarded: () => {},
  ipadOnboardRequested: false,
  setIpadOnboardRequested: () => {},
  windowsPOnboarded: "pending",
  setWindowsPOnboarded: ()=>{},
  ipadPOnboarded: "pending",
  setIpadPOnboarded: ()=>{},
  taskOnProgress:[],
  setTaskOnProgress:()=>{},
  apiToken:false,
  setApiToken:()=>{},
  numiatoStatus:"1000",
  setNumiatoStatus:()=>{}
})

export const DeviceStatusContext = createContext({
  deviceStatus : null,
  setDeviceStatus : () => {}
})