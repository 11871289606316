import React from "react";

function PageTitle({ title, Icon, iconOnClick, children, ...attributes }) {
  return (
    <div className="w-full shadow-sm">
      <div className="mx-auto max-w-6xl pt-11 pb-4 px-4 flex justify-between">
        <div className="flex">
        <div
          className="text-2xl font-semibold flex cursor-pointer "
          onClick={iconOnClick}
        >
          {Icon && <Icon />}
        </div>
        <div
          className="text-2xl font-semibold flex "
        >
          {title && title}
        </div></div>
        <div className="justify-end flex">{children}</div>
      </div>
    </div>
  );
}

export default PageTitle;
