import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import LoadingText from "../layout/loading/LoadingText";
import Tooltip from "../layout/tooltip/Tooltip";
function StatusBox({ deviceInfo, deviceStatus, enrolled }) {
  const { t } = useTranslation("global");
  if (enrolled) {
    return (
      <div className="space-y-5 p-5 ">
        <DeviceStatusField info={deviceStatus} />
        <StatusBoxField
          title={t("pages.device_detail.os")}
          value={deviceInfo.operatingSystem}
        />
        <StatusBoxField
          title={t("pages.device_detail.os_version")}
          value={deviceInfo.operatingSystemVerion}
        />
        {deviceInfo.type == "windows" && (
          <StatusBoxField
            title={t("pages.device_detail.os_edition")}
            value={deviceInfo.operatingSystemEdition}
          />
        )}
        <StatusBoxField
          title={t("pages.device_detail.last_sync")}
          value={deviceInfo.lastSync}
          date
        />
        <StatusBoxMultiline
          title={t("pages.device_detail.last_used_user")}
          value={deviceInfo.usersLoggedOn}
        />
      </div>
    );
  } else {
    return (
      <div className="space-y-5 p-5 ">
        <DeviceStatusField info={deviceStatus} />
      </div>
    );
  }
}

export default StatusBox;

const StatusBoxMultiline = ({ title, value }) => {
  return (
    <div className="w-full space-y-2">
      <p className="font-medium">{title}</p>
      {value &&
        value.map(({ userPrincipalName, lastLogOnDateTime }, index) => (
          <Tooltip title={userPrincipalName}>
            <div key={`upn${index}`}>
              <p className="truncate">{userPrincipalName}</p>
              <p className="pb-2 truncate">
                {`${moment(lastLogOnDateTime).format("L")}, ${moment(
                  lastLogOnDateTime
                ).format("H:mm")}`}
              </p>
            </div>
          </Tooltip>
        ))}
    </div>
  );
};

const StatusBoxField = ({ title, value, date }) => {
  return (
    <div className="w-full space-y-2">
      <p className="font-medium">{title}</p>
      <p className="text-fgray-400 truncate ">
        {date
          ? `${moment(value).format("L")}, ${moment(value).format("H:mm")}`
          : value}
      </p>
    </div>
  );
};

const DeviceStatusField = ({ info }) => {
  const { t } = useTranslation("global");
  const StatusLine = () => {
    if (info[0].status === 200) {
      return (
        <div className="w-full space-y-2">
          <p className="font-medium">{t("pages.device_detail.status")}</p>
          <div
            className={` border-fgray-200  w-full  align-middle my-auto text-fgray-400`}
          >
            <div className="flex">
              <div className=" bg-lime-600 rounded-full w-2 h-2 align-middle my-auto mr-2"></div>
              <div className="text-lime-600 font-medium">
                {t("metrics.status.200.title")}
              </div>
            </div>
            <p className="line-clamp-3">
              {t("metrics.status.200.description")}
            </p>
          </div>
        </div>
      );
    } else if (info[0].status === 300) {
      return (
        <>
          <div
            className={` border-fgray-200  w-full  align-middle my-auto text-fgray-400`}
          >
            <div className=" flex">
              <div className="bg-fstatus-300 rounded-full w-2 h-2 align-middle my-auto mr-2" />
              <div className="text-fstatus-300  font-medium">
                {" "}
                {t("metrics.status.300.title")}
              </div>
            </div>
            <p className="line-clamp-3">{t(`metrics.${info[0].id}.warning`)}</p>
          </div>
        </>
      );
    } else if (info[0].status === 400) {
      return (
        <>
          <div
            className={` border-fgray-200 w-full align-middle my-auto text-fgray-400`}
          >
            <div className=" flex">
              <div className="bg-fstatus-400 rounded-full w-2 h-2 align-middle my-auto mr-2" />
              <div className="text-fstatus-400  font-medium">
                {" "}
                {t("metrics.status.400.title")}
              </div>
            </div>
            <p className="line-clamp-3">{t(`metrics.${info[0].id}.danger`)}</p>
          </div>
        </>
      );
    }
  };
  return info ? <StatusLine /> : <LoadingText />;
};
