import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { postApi } from "../../tools/axiosInstances";
import MultilineTranslation from "../layout/MultilineTranslation";
import StandardButton from "../layout/buttons/StandardButton";
import { useToggleComponent } from "../../tools/hooks";
import ModalCard, {
  ModalCardBody,
  ModalCardTitle,
  ModalCardButtons,
} from "../layout/ModalCard";
function ApplicationActionModal({
  selected,
  action,
  showModal,
  toggle,
  clear,
  filter,
  setTaskId,
}) {
  const { t } = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const wrapper = useRef(null);
  useToggleComponent(wrapper, toggle);

  function handleActionClick() {
    setLoading(true);
    let reqBody = "";
    switch (action) {
      case "unmanage":
        reqBody = {
          kind: "unm",
          target: "app",
          detail: [],
        };
        break;
      case "uninstall":
        reqBody = {
          kind: "usg",
          target: "app",
          detail: [],
        };
        break;
      case "install":
        reqBody = {
          kind: "asg",
          target: "app",
          detail: [],
        };
        break;
      case "delete":
        reqBody = {
          kind: "del",
          target: "app",
          detail: [],
        };
        break;
      default:
        break;
    }
    selected &&
      selected.map((app) => {
        reqBody.detail.push({ appId: app.original.id, groupId: filter });
      });

    postApi("/tasks/", reqBody).then((response) => {
      toggle();
      setTaskId(response.data.identifier);
      setLoading(false);
      clear && clear();
    });
  }

  return (
    <>
      <ModalCard wrapper={wrapper}>
        <ModalCardTitle>
          {selected.length == 1
            ? t(`pages.application.modal.title_${action}_1`, {
                appName: selected.map((row) => row.original.displayName),
              })
            : t(`pages.application.modal.title_${action}_n`, {
                appCount: selected.length,
              })}
        </ModalCardTitle>
        <ModalCardBody>
          {selected.length == 1
            ? t(`pages.application.modal.confirm_${action}_1`, {
                appName: selected.map((row) => row.original.displayName),
              })
            : t(`pages.application.modal.confirm_${action}_n`, {
                appCount: selected.length,
              })}
        </ModalCardBody>
        <ModalCardButtons>
          <StandardButton onClick={toggle} subtle>
            {t("pages.application.modal.cancel")}
          </StandardButton>
          <StandardButton onClick={handleActionClick} loading={loading}>
            {t(`pages.application.modal.${action}`)}
          </StandardButton>
        </ModalCardButtons>
      </ModalCard>
    </>
  );
}

export default ApplicationActionModal;
