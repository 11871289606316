import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { getApi, postApi } from "../tools/axiosInstances";
import Sidebar from "../components/layout/Sidebar";
import ApplicationTableV2 from "../components/application/ApplicationTableV2";
import ApplicationTable from "../components/application/ApplicationTable";
import GroupProgress from "../components/group/GroupProgress";
import LoadingTable from "../components/layout/loading/LoadingTable";
import RefreshButton from "../components/layout/buttons/RefreshButton";
import DeviceModeBar from "../components/layout/DeviceModeBar";
import ApplicationActionModal from "../components/application/ApplicationActionModal";
import ActionButton from "../components/layout/buttons/ActionButton";
import { PortalContext } from "../PortalContext";
import { ReactComponent as UnmanageLogo } from "../components/icons/unmanage.svg";
import { ReactComponent as UninstallLogo } from "../components/icons/uninstall.svg";
import { ReactComponent as InstallLogo } from "../components/icons/install.svg";
import { ReactComponent as RemoveLogo } from "../components/icons/remove.svg";
import IpadOnboarding from "../components/device/IpadOnboarding";
import { useCheckLicenseStatus } from "../tools/utilties";
function Application() {
  const tableRef = useRef();
  const { t } = useTranslation("global");
  const [showModalUninstall, setShowModalUninstall] = useState(false);
  const [showModalInstall, setShowModalInstall] = useState(false);
  const [showModalUnmanage, setShowModalUnmanage] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const globalId = "99999999-9999-9999-9999-999999999999";
  const [loadingr, setLoadingr] = useState(false);
  const [taskId, setTaskId] = useState(0);
  const [filter, setFilter] = useState(globalId);
  const [selected, setSelected] = useState([]);
  const [reload, setReload] = useState(0);
  const [appsData, setAppsData] = useState(false);
  const [clear, setClear] = useState(0);
  const { deviceMode, ipadOnboarded } = useContext(PortalContext);
  const handleReload = () => setReload((p) => p + 1);
  useCheckLicenseStatus();
  useEffect(() => {
    setLoadingr(true);
    setAppsData(null);
    getApi(`/application/status/${filter}/`, {}).then((list) => {
      setAppsData(list.data);
      setLoadingr(false);
    });
  }, [filter, reload]);

  function toggleModalUninstall() {
    selected.length > 0 && setShowModalUninstall(!showModalUninstall);
  }
  function toggleModalInstall() {
    selected.length > 0 && setShowModalInstall(!showModalInstall);
  }
  function toggleModalUnmanage() {
    selected.length > 0 && setShowModalUnmanage(!showModalUnmanage);
  }
  const toggleModalDelete = () =>
    selected.length > 0 && setShowModalDelete(!showModalDelete);
  const clearSelected = () => {
    setClear((p) => p + 1);
  };
  return (
    <>
      {/*Title Bar*/}
      <div className="w-full shadow-sm">
        <div className="mx-auto max-w-6xl pt-11 pb-4 px-4 flex justify-between ">
          <div className="text-2xl font-medium">
            {t("pages.application.title")}
          </div>
          <div className="justify-end flex">
            <RefreshButton loading={loadingr} handleRefresh={handleReload} />
            <ActionButton
              id="unmanage"
              Icon={UnmanageLogo}
              onClick={toggleModalUnmanage}
              tooltip={`tooltip.application.${deviceMode}.unmanage`}
              subtle
            >
              {t("pages.application.unmanage")}
            </ActionButton>
            <ActionButton
              id="delete"
              Icon={RemoveLogo}
              onClick={toggleModalDelete}
              tooltip={`tooltip.application.${deviceMode}.delete`}
              subtle
            >
              {t("pages.application.delete")}
            </ActionButton>
            <ActionButton
              Icon={UninstallLogo}
              id="uninstall"
              onClick={toggleModalUninstall}
              tooltip={`tooltip.application.${deviceMode}.uninstall`}
              subtle
            >
              {t("pages.application.uninstall")}
            </ActionButton>
            <ActionButton
              id="install"
              Icon={InstallLogo}
              onClick={toggleModalInstall}
              tooltip={`tooltip.application.${deviceMode}.install`}
              subtle
            >
              {t("pages.application.install")}
            </ActionButton>
          </div>
        </div>
      </div>
      {/* Sidebar */}
      <div className="mx-auto max-w-6xl m-5 flex  ">
        <div className="w-auto mr-5">
          <Sidebar
            filter={filter}
            setFilter={setFilter}
            globalId={globalId}
          ></Sidebar>
        </div>
        {/* Content */}
        <div className="w-full">
          <div className="w-[52rem] z-20 sticky">
            <DeviceModeBar target="application" />
          </div>
          <div className="w-full overflow-y-scroll h-[calc(100vh-265px)] mb-12">
            {appsData && !(ipadOnboarded === "pending") ? (
              deviceMode == "ipad" && !ipadOnboarded ? (
                <IpadOnboarding target="application" />
              ) : appsData ? (
                <ApplicationTableV2
                  appData={appsData}
                  globalId={globalId}
                  filter={filter}
                  setSelected={setSelected}
                  refresh={reload}
                  clear={clear}
                />
              ) : (
                <LoadingTable />
              )
            ) : (
              <LoadingTable></LoadingTable>
            )}
          </div>
        </div>
        {/* <div className="w-full"> {appsData && <AppTable info={appsData} globalId={globalId} filter={filter}/>}</div> */}
      </div>
      {taskId ? (
        <GroupProgress
          taskId={taskId}
          setTaskId={setTaskId}
          reload={setReload}
        />
      ) : (
        ""
      )}

      {/* Uninstall Modal content  */}
      {showModalUninstall && (
        <ApplicationActionModal
          action="uninstall"
          selected={selected}
          showModal={showModalUninstall}
          toggle={toggleModalUninstall}
          clear={clearSelected}
          setTaskId={setTaskId}
          filter={filter}
        />
      )}
      {showModalInstall && (
        <ApplicationActionModal
          action="install"
          selected={selected}
          showModal={showModalInstall}
          toggle={toggleModalInstall}
          clear={clearSelected}
          setTaskId={setTaskId}
          filter={filter}
        />
      )}
      {showModalUnmanage && (
        <ApplicationActionModal
          action="unmanage"
          selected={selected}
          showModal={showModalUnmanage}
          toggle={toggleModalUnmanage}
          clear={clearSelected}
          setTaskId={setTaskId}
          filter={filter}
        />
      )}
      {showModalDelete && (
        <ApplicationActionModal
          action="delete"
          selected={selected}
          showModal={showModalDelete}
          toggle={toggleModalDelete}
          clear={clearSelected}
          setTaskId={setTaskId}
          filter={filter}
        />
      )}
    </>
  );
}

export default Application;
