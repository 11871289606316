import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { getApi } from "../tools/axiosInstances";
import TabBar from "../components/layout/TabBar";
import GroupGrid from "../components/group/GroupGrid";
import NewGroupButton from "../components/group/NewGroupButton";
import { isEqual } from "lodash";
import LoadingGroupCard from "../components/layout/loading/LoadingGroupCard";
import { PortalContext } from "../PortalContext";
import { useCheckLicenseStatus } from "../tools/utilties";
function Group({ refresh, makeRefresh }) {
  const { t } = useTranslation("global");
  const [tab, setTab] = useState(0);
  const [filtered, setFiltered] = useState(false);
  const [categories, setCategories] = useState(() => {
    const allCategories = JSON.parse(sessionStorage.getItem("allCategories"));
    return allCategories || false;
  });
  const [rooms, setRooms] = useState(() => {
    const allRooms = JSON.parse(sessionStorage.getItem("allRooms"));
    return allRooms || false;
  });
  useCheckLicenseStatus();
  function tabHandler(name) {
    setTab(name);
    name == "All" ? setFiltered(false) : setFiltered(true);
  }
  useEffect(() => {
    getApi("/category/", {}).then((list) => {
      if (
        !isEqual(
          JSON.stringify(list.data),
          sessionStorage.getItem("allCategories")
        )
      ) {
        setCategories(list.data);
        sessionStorage.setItem("allCategories", JSON.stringify(list.data));
      }
    });
    getApi("/room/", {}).then((list) => {
      if (
        !isEqual(
          JSON.stringify(list.data["value"]),
          sessionStorage.getItem("allRooms")
        )
      ) {
        setRooms(list.data["value"]);
        sessionStorage.setItem("allRooms", JSON.stringify(list.data["value"]));
      }
    });
  }, [refresh]);

  return (
    <>
      {/*Title Bar*/}
      <div className="w-full shadow-sm">
        <div className="mx-auto max-w-6xl pt-11 pb-4 px-4 flex justify-between ">
          <div className="text-2xl font-medium">{t("pages.group.title")}</div>
          <div className="justify-end">
            <NewGroupButton makeRefresh={makeRefresh} />
          </div>
        </div>
      </div>
      {/* Content */}
      <div className="mx-auto max-w-6xl m-5 overflow-auto">
        <TabBar
          options={[
            { title: t("words.all"), id: 0 },
            { title: t("pages.group.category"), id: 1 },
            { title: t("pages.group.room"), id: 2 },
          ]}
          handler={tabHandler}
          activeTab={tab}
        />
      </div>
      <div className="mx-auto max-w-6xl m-5 flex overflow-auto ">
        {categories && rooms ? (
          <GroupGrid
            categories={categories}
            rooms={rooms}
            filter={tab}
            makeRefresh={makeRefresh}
          />
        ) : (
          <LoadingGroupCard />
        )}
      </div>
    </>
  );
}

export default Group;
