import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getApi } from "../../tools/axiosInstances";
import { ReactComponent as WarningLogo } from "../icons/warning.svg";
import { ReactComponent as OkLogo } from "../icons/ok.svg";
import LoadingTable from "../layout/loading/LoadingTable";
import { PortalContext } from "../../PortalContext";
import IpadOnboarding from "../device/IpadOnboarding";

function IpadAuthorizationInfo({}) {
  const { t } = useTranslation("global");
  const [info, setInfo] = useState(false);
  const { ipadOnboarded, ipadOnboardRequested } = useContext(PortalContext);
  useEffect(() => {
    getApi("/settings/ipad/mdmstatus/", {}).then((data) => {
      setInfo(data.data);
    });
  }, []);

  return (
    <>
      {ipadOnboarded || !ipadOnboardRequested ? (
        <div className="max-w-[850px]">
          {info ? (
            <div className="m-50 w-full">
              <div className="justify-center mb-8">
                <div className="text-xl font-medium my-4">
                  {t("pages.settings.ipads.title")}
                </div>
                <div className="text-base text-fgray-400">
                  {t("pages.settings.ipads.description")}
                </div>
              </div>
              <div className="justify-start items-center my-4 flex">
                <div className="w-36 text-base text-fgray-400">
                  {t("pages.device_detail.status")}
                </div>
                {info.general ? (
                  <div className="justify-start items-center  flex">
                    <div className="text-fstatus-200">{t("words.activated")}</div>
                    <OkLogo className="h-6 w-6 text-fstatus-200 mx-2" />
                  </div>
                ) : (
                  <div className="justify-start items-center  flex">
                    <div className="text-fstatus-400">{t("words.deactivated")}</div>
                    <WarningLogo className="text-fstatus-400 h-6 w-6 mx-2" />
                  </div>
                )}
              </div>
              {info.general && (
                <div className="justify-start items-center  flex">
                  <div className="w-36 text-base text-fgray-400">
                    {t("words.expiration_date")}
                  </div>
                  <div>{`${moment(info.expiration).format("L")}, ${moment(
                    info.expiration
                  ).format("H:mm")}`}</div>
                </div>
              )}
            </div>
          ) : (
            <div className="m-50 w-full">
              <LoadingTable />
            </div>
          )}
        </div>
      ) : (
        <IpadOnboarding />
      )}
    </>
  );
}

export default IpadAuthorizationInfo;
