import React from "react";

function LoadingHome() {
  return (
    <div className="mx-auto max-w-6xl ">
      <div className="animate-pulse grid grid-cols-4 gap-4">


        <div className="border w-64 rounded-2xl content-center text-center hover:text-forange-400 hover:border-forange-400 hover:font-medium font-normal active:bg-forange-400 active:text-white">
          <div className="align-middle flex justify-center items-center">
            <div className="h-16 mt-4 bg-fgray-200 rounded-full w-16" /> 
          </div>
          <p className="pb-3 px-4 list-none content-center  active:rounded" />
          <p className="pb-6 px-3 m-3 rounded content-center bg-fgray-200" />
        </div>

        <div className="border w-64 rounded-2xl content-center text-center hover:text-forange-400 hover:border-forange-400 hover:font-medium font-normal active:bg-forange-400 active:text-white">
          <div className="align-middle flex justify-center items-center">
            <div className="h-16 mt-4 bg-fgray-200 rounded-full w-16" /> 
          </div>
          <p className="pb-3 px-4 list-none content-center  active:rounded" />
          <p className="pb-6 px-3 m-3 rounded content-center bg-fgray-200" />
        </div>

        <div className="border w-64 rounded-2xl content-center text-center hover:text-forange-400 hover:border-forange-400 hover:font-medium font-normal active:bg-forange-400 active:text-white">
          <div className="align-middle flex justify-center items-center">
            <div className="h-16 mt-4 bg-fgray-200 rounded-full w-16" /> 
          </div>
          <p className="pb-3 px-4 list-none content-center  active:rounded" />
          <p className="pb-6 px-3 m-3 rounded content-center bg-fgray-200" />
        </div>

        <div className="border w-64 rounded-2xl content-center text-center hover:text-forange-400 hover:border-forange-400 hover:font-medium font-normal active:bg-forange-400 active:text-white">
          <div className="align-middle flex justify-center items-center">
            <div className="h-16 mt-4 bg-fgray-200 rounded-full w-16" /> 
          </div>
          <p className="pb-3 px-4 list-none content-center  active:rounded" />
          <p className="pb-6 px-3 m-3 rounded content-center bg-fgray-200" />
        </div>

       
      </div>
    </div>
  );
}

export default LoadingHome;
