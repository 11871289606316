import React, { useState, useEffect,useContext } from "react";
import TitleProgressBar from "../../layout/loading/TitleProgressBar";
import { useTranslation } from "react-i18next";
import Spinner from "../../layout/loading/Spinner";
import { PortalContext } from "../../../PortalContext";
import DeviceModeBar from "../../layout/DeviceModeBar";

function OnboardProgress({ task }) {
  const {deviceMode,setWindowsPOnboarded,setIpadPOnboarded} = useContext(PortalContext)
  const { t } = useTranslation("global");
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    if (progress == 100) {
      if(deviceMode==="windows"){
        setWindowsPOnboarded(true)
      }else if (deviceMode==="ipad"){
        setIpadPOnboarded(true)
      }
      sessionStorage.setItem("PolicyMgmtOnboarded", true);
      sessionStorage.removeItem(`onboardTask_${deviceMode}`)
      // window.location.reload();
    }
  }, [progress]);

  return (
    <>
      {/*Title Bar*/}
      <div className="w-full">
        <TitleProgressBar
          task={task}
          progress={progress}
          setProgress={setProgress}
        />
      </div>
      {/* Content */}
      <div className="mx-auto max-w-6xl px-4 m-5  ">
        <div className="my-4 text-fgray-400 text-xl">
          <DeviceModeBar/>
          <div className="py-2 my-2 text-xl">
            <div className="flex my-4">
              <div className="my-1">
                {progress > 5 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-8 h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12"
                    />
                  </svg>
                ) : (
                  <Spinner />
                )}
              </div>
              <div className="text-fblack mx-4 my-2">
                {t("pages.policy.onboard.process.first")}
              </div>
            </div>
            <div className="flex my-4">
              <div className="my-1">
                {progress > 40 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-8 h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12"
                    />
                  </svg>
                ) : (
                  <Spinner />
                )}
              </div>
              <div className="text-fblack mx-4 my-2">
                {t("pages.policy.onboard.process.second")}
              </div>
            </div>
            <div className="flex my-4">
              <div className="my-1">
                {progress >= 95 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-8 h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12"
                    />
                  </svg>
                ) : (
                  <Spinner />
                )}
              </div>
              <div className="text-fblack mx-4 my-2">
                {t("pages.policy.onboard.process.third")}
              </div>
            </div>
            <div className="flex my-4">
              <div className="my-1">
                {progress > 99 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-8 h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12"
                    />
                  </svg>
                ) : (
                  <Spinner />
                )}
              </div>
              <div className="text-fblack mx-4 my-2">
                {t("pages.policy.onboard.process.fourth")}
              </div>
            </div>
            <div className="flex my-4">
              {progress < 0 ? (
                <>
                  <div className="my-1 text-forange-400">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                      />
                    </svg>
                  </div>
                  <div className="text-forange-400 font-medium mx-4 my-2">
                    {t("pages.policy.onboard.process.error")}
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OnboardProgress;
