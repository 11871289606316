import React, { useState, useMemo, useEffect } from "react";
import {
  createColumnHelper,
  getSortedRowModel,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import moment from "moment";
import IndeterminateCheckbox from "../layout/IndeterminateCheckbox";
import SortingIcon from "../layout/SortingIcon";
import { rankItem } from "@tanstack/match-sorter-utils";

function PortalAdminsTable({ userInfo, setSelected }) {
  const { t } = useTranslation("global");
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnVisibility, setColumnVisibility] = useState({
    id: false,
  });
  const [rowSelection, setRowSelection] = useState({});
  const [lastSelected, setLastSelected] = useState([]);
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const data = useMemo(() => [...userInfo], [userInfo]);

  const columns = [
    columnHelper.accessor("select", {
      header: ({ table }) => (
        <IndeterminateCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      size: 64,
      cell: ({ row }) => (
        <IndeterminateCheckbox
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect}
          indeterminate={row.getIsSomeSelected()}
          onChange={(e) => {
            handleSelection(e, row);
          }}
        />
      ),
      enableSorting: false,
      enableResizing: false,
    }),
    columnHelper.accessor("principalDisplayName", {
      header: () => <span>{t("pages.settings.table.displayName")}</span>,
      minSize: 150,
      size: 180,
    }),
    columnHelper.accessor("userPrincipalName", {
      header: () => <span>{t("pages.settings.table.email")}</span>,
      minSize: 150,
      size: 250,
    }),
    columnHelper.accessor("creationTimestamp", {
      header: () => <span>{t("pages.settings.table.date_added")}</span>,
      minSize: 100,
      size: 100,
      cell: (info) => (
        <span>{moment(info.getValue()).format("DD-MMM-YYYY")}</span>
      ),
    }),
    columnHelper.accessor("role", {
      header: () => <span>{t("pages.settings.modal.add.role")}</span>,
      minSize: 100,
      size: 120,
      cell: (info) => <span>{t(`roles.${info.getValue()}`)}</span>,
    }),
    columnHelper.accessor("id"),
  ];

  useEffect(() => {
    setSelected(table.getSelectedRowModel().flatRows);
  }, [rowSelection]);
  const fuzzyFilter = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);
    // Store the itemRank info
    addMeta({
      itemRank,
    });
    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      globalFilter,
      rowSelection,
      sorting,
      columnVisibility,
    },
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    enableRowSelection: true,
    enableGlobalFilter: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onglobalFilterFn: fuzzyFilter,
    GlobalFilterChange: setGlobalFilter,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });
  const handleSelection = (e, row) => {
    setLastSelected(row);
    if (e.nativeEvent.shiftKey) {
      const sortedRows = table.getSortedRowModel().rows;
      const lastSelectedIndex = sortedRows.findIndex(
        (r) => r.id == lastSelected.id
      );
      const clickedIndex = sortedRows.findIndex((r) => r.id == row.id);

      if (lastSelectedIndex < clickedIndex) {
        for (let i = lastSelectedIndex; i <= clickedIndex; i++) {
          const element = sortedRows[i];
          !element.getIsSelected() && element.toggleSelected(true);
        }
      } else {
        for (let i = clickedIndex; i <= lastSelectedIndex; i++) {
          const element = sortedRows[i];
          !element.getIsSelected() && element.toggleSelected(true);
        }
      }
    } else {
      row.toggleSelected();
    }
  };
  return (
    <>
      <div
        className="p-2 w-full"
        // {...{style: {width: table.getCenterTotalSize()}}}
      >
        <table
          className=" mb-12  overflow-scroll"
          {...{ style: { width: table.getCenterTotalSize() } }}
        >
          <thead className="border-1 top-0 sticky bg-white ">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={`py-4  text-fgray-300 truncate cursor-pointer`}
                    style={{ width: header.column.getSize() }}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div className="flex justify-end">
                      <div className={`flex w-full px-4`}>
                        <p
                          className={`transition hover:text-black  ${
                            header.column.getIsSorted() && " text-black "
                          } `}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </p>
                        {header.column.getCanSort() &&
                          (header.column.getIsSorted() ? (
                            header.column.getIsSorted() === "asc" ? (
                              <SortingIcon status={"asc"} />
                            ) : (
                              <SortingIcon status={"desc"} />
                            )
                          ) : (
                            <SortingIcon status={"plain"} />
                          ))}
                      </div>
                      {/* Resizing */}
                      {header.column.getCanResize() && (
                        <div
                          onMouseDown={header.getResizeHandler()}
                          onTouchStart={header.getResizeHandler()}
                          className={`h-10 border-r-2 select-none  touch-none align-middle absolute cursor-col-resize hover:border-r-4 hover:border-fgray-300 ${
                            header.column.getIsResizing()
                              ? " border-fgray-400 "
                              : "  "
                          }`}
                        >
                          {/* <img src={Dots} className="select-none touch-none " /> */}
                        </div>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className="border-y-2 hover:bg-forange-100 text-gray-700 hover:font-medium font-normal focus:bg-forange-400 "
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={
                        cell.column.getCanResize()
                          ? { width: cell.column.getSize() }
                          : {}
                      }
                      className={`py-5 font-xs px-4 ${
                        cell.column.columnDef.className &&
                        flexRender(
                          cell.column.columnDef.className,
                          cell.getContext()
                        )
                      } `}
                    >
                      <p
                        className="truncate h-6 text-left   "
                        style={
                          cell.column.getCanResize()
                            ? { width: cell.column.getSize() }
                            : {}
                        }
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </p>
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  className="text-fgray-400 text-xl w-[885px] p-5"
                  colSpan="5"
                >
                  {t("pages.settings.table.no_admins")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
export default PortalAdminsTable;
