import React, { useState } from "react";
import Field from "../layout/design/Field";
import EditableField from "../layout/design/EditableField";
import { useTranslation } from "react-i18next";
import IconButton from "../layout/buttons/IconButton";
import { ReactComponent as SaveIcon } from "../icons/save.svg";
import { postApi } from "../../tools/axiosInstances";
function BasicInformation({ deviceInfo, toggleAssign, enrolled,setDeviceInfo }) {
  const { t } = useTranslation("global");
  const [edited, setEdited] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOnSave = () => {
    setLoading(true);
    const newDeviceName =
      (document.getElementById("deviceName") &&
        document.getElementById("deviceName")?.value != deviceInfo.deviceName &&
        document.getElementById("deviceName")?.value) ||
      false;
    const newNotes =
      (document.getElementById("deviceNotes") &&
        document.getElementById("deviceNotes")?.value != deviceInfo.notes &&
        document.getElementById("deviceNotes")?.value) ||
      (document.getElementById("deviceNotes")?.value === "" && "_blank") ||
      false;
    if (newDeviceName || newNotes) {
      const reqBody = {
        kind: "rnm",
        target: "dev",
        detail: [
          {
            deviceId: deviceInfo.id,
            newName: newDeviceName,
            newNotes: newNotes,
          },
        ],
      };
      postApi("/tasks/", reqBody).then((response) => {
        setLoading(false);
        setEdited(false);
        enrolled && setDeviceInfo && setDeviceInfo(d => ({...d,pendingTasks:[...d.pendingTasks,{actionName:"setDeviceName",actionState:"pending"}]}))
      });
    } else {
      setLoading(false);
      setEdited(false);
    }
  };
  if (!enrolled) {
    return (
      <>
        <div className="w-full pr-2 space-y-5">
          {deviceInfo.type == "windows" ? (
            <EditableField
              id="deviceName"
              title={t("pages.device_detail.devicename")}
              value={deviceInfo.deviceName}
              setEditMode={setEdited}
              validator="^(?!\d+$)[a-zA-Z0-9-]{1,15}$"
              invalidTip={t("pages.device_detail.devicename_invalid")}
            />
          ) : (
            <Field
              readOnly
              title={t("pages.device_detail.devicename")}
              value={deviceInfo.deviceName}
            />
          )}
            <Field
              readOnly
              title={t("pages.device_detail.device_category")}
              value={t(
                `categories.name.${deviceInfo.deviceCategory}`,
                deviceInfo.deviceCategory
              )}
              onClick={toggleAssign}
            />
          <Field
            disabled
            title={t("pages.device_detail.manufacturer")}
            value={deviceInfo.manufacturer}
          />
          <Field
            readOnly
            title={t("pages.device_detail.model")}
            value={deviceInfo.model}
            disabled
          />
          <Field
            disabled
            title={t("pages.device_detail.serialnumber")}
            value={deviceInfo.serialNumber}
          />
        </div>
        <div className="mt-10 w-full">
          {deviceInfo.type == "windows" &&
            (edited ? (
              <IconButton Icon={SaveIcon} onClick={handleOnSave}>
                {t("words.save")}
              </IconButton>
            ) : (
              <IconButton Icon={SaveIcon} disabled>
                {t("words.save")}
              </IconButton>
            ))}
        </div>
      </>
    );
  } else
    return (
      <>
        <div className="w-full pr-2 space-y-5">
          {deviceInfo.type == "windows" ? (
            <EditableField
              id="deviceName"
              title={t("pages.device_detail.devicename")}
              value={deviceInfo.deviceName}
              setEditMode={setEdited}
              validator="^(?!\d+$)[a-zA-Z0-9-]{1,15}$"
              invalidTip={t("pages.device_detail.devicename_invalid")}
            />
          ) : (
            <Field
              readOnly
              title={t("pages.device_detail.devicename")}
              value={deviceInfo.deviceName}
            />
          )}
            <Field
              readOnly
              title={t("pages.device_detail.device_category")}
              value={deviceInfo.deviceCategory}
              onClick={toggleAssign}
            />
          <Field
            readOnly
            title={t("pages.device_detail.room")}
            value={deviceInfo.roomDisplayName}
            onClick={toggleAssign}
          />
          <Field
            disabled
            readOnly
            title={t("pages.device_detail.username")}
            value={deviceInfo.username}
          />
          <EditableField
            id="deviceNotes"
            title={t("pages.device_detail.notes")}
            value={deviceInfo.notes}
            setEditMode={setEdited}
            placeholder="-"
            validator={"^(.{0,255})$"}
            invalidTip={t("pages.device_detail.notes_invalid")}
            />
          <Field
            disabled
            title={t("pages.device_detail.serialnumber")}
            value={deviceInfo.serialNumber}
          />
        </div>
        <div className="mt-10 w-full">
          {edited ? (
            <IconButton Icon={SaveIcon} onClick={handleOnSave}>
              {loading ? t("words.loading") : t("words.save")}
            </IconButton>
          ) : (
            <IconButton Icon={SaveIcon} disabled>
              {t("words.save")}
            </IconButton>
          )}
        </div>
      </>
    );
}



export default BasicInformation;
