import React, { useState, useEffect } from "react";
import { getApi } from "../../tools/axiosInstances";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";
import LoadingSidebar from "./loading/LoadingSidebar";

function Sidebar({ unassigned, filter, setFilter, globalId }) {
  const { t } = useTranslation("global");
  const [roomCount, setRoomCount] = useState(0);
  const [categoryCount, setCategoryCount] = useState(0);
  const [unassignedRoomCount, setUnassignedRoomCount] = useState(0);
  const [unassignedCategoryCount, setUnassignedCategoryCount] = useState(0);
  const [categoryList, setCategoryList] = useState(() => {
    const allCategories = JSON.parse(sessionStorage.getItem("allCategories"));
    return allCategories || false;
  });
  const [roomList, setRoomList] = useState(() => {
    const allRooms = JSON.parse(sessionStorage.getItem("allRooms"));
    return allRooms || false;
  });

  useEffect(() => {
    getApi("/room/", {}).then((list) => {
      if (
        !isEqual(
          JSON.stringify(list.data["value"]),
          sessionStorage.getItem("allRooms")
        )
      ) {
        setRoomList(list.data["value"]);
        sessionStorage.setItem("allRooms", JSON.stringify(list.data["value"]));
      }
      unassigned
        ? setRoomCount(list.data["value"].length + 1)
        : setRoomCount(list.data["value"].length);
    });
    getApi("/category/", {}).then((list) => {
      if (!isEqual(list.data, sessionStorage.getItem("allCategories"))) {
        setCategoryList(list.data);
        sessionStorage.setItem("allCategories", JSON.stringify(list.data));
      }
      unassigned
        ? setCategoryCount(list.data.length + 1)
        : setCategoryCount(list.data.length);
    });
    // getApi("/category/unassigned/",{})
    // .then((list) => {
    //   setUnassignedCategoryCount(list.data["count"]);
    // });

    // getApiGraph(
    //   accessTokenResponse.accessToken,
    //   "/room/unassigned/",
    //   {}
    // ).then((list) => {
    //   setUnassignedRoomCount(list.data["count"].length);
    // });
  }, []);

  function handleToggleFilter(name) {
    setFilter(name);
  }

  return (
    <>
      <nav className=" mr-5 w-64 mb-12 ">
        <ul className="nav flex flex-col w-full">
          {/* allDevices */}
          <li
            className={
              `rounded nav-item px-4 py-3 mx-4 list-none items-center cursor-pointer hover:font-medium font-normal active:rounded ` +
              (filter === globalId
                ? "bg-forange-400 text-white font-medium"
                : " hover:text-forange-400 ")
            }
            aria-current="page"
            key={globalId}
            onClick={(e) => {
              e.preventDefault();
              handleToggleFilter(globalId);
            }}
          >
            {t("sidebar.all_devices")}
          </li>
          {/* Categories */}
          <details className="group open:transition open:duration-300" open>
            <summary className="px-4 py-3 mx-4 list-none flex flex-wrap items-center cursor-pointer focus-visible:outline-none rounded group-open:rounded-b-none relative text-fgray-300 ">
              <div className=" group-open:rotate-180 transition duration-300">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M7 14.5L11.6464 9.85355C11.8417 9.65829 12.1583 9.65829 12.3536 9.85355L17 14.5"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  ></path>
                </svg>
              </div>
              <div>
                <h3 className="font-medium text-left">
                  {t("sidebar.category.title")} ({categoryCount})
                </h3>
              </div>
            </summary>
            <ul className="open:transition open:duration-300">
              {categoryList ? (
                categoryList.map(
                  ({ id, displayName, shared, description, groupId }) => (
                    <li
                      className={
                        `rounded nav-item px-4 py-3 mx-4 list-none items-center cursor-pointer hover:font-medium font-normal  active:rounded ` +
                        (filter === groupId
                          ? "bg-forange-400 text-white font-medium"
                          : " hover:text-forange-400 ")
                      }
                      key={id}
                      onClick={(e) => {
                        e.preventDefault();
                        handleToggleFilter(groupId);
                      }}
                    >
                      {shared
                        ? t(
                            `categories.name.${displayName}`,
                            `${displayName.substring(7)} ${t(
                              "categories.name.shared"
                            )}`
                          )
                        : t(`categories.name.${displayName}`, displayName)}
                    </li>
                  )
                )
              ) : (
                <LoadingSidebar />
              )}
              {unassigned && categoryList && (
                <li
                  className={
                    `rounded nav-item px-4 py-3 mx-4 list-none items-center cursor-pointer  hover:font-medium font-normal active:rounded ` +
                    (filter === "No_Category"
                      ? "bg-forange-400 text-white font-medium"
                      : " hover:text-forange-400 ")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    handleToggleFilter("No_Category");
                  }}
                >
                  {t("sidebar.no_category")} ( )
                </li>
              )}
            </ul>
          </details>
          {/* Rooms */}
          <details className="group open:transition open:duration-300" open>
            <summary className="px-4 py-3 mx-4 list-none flex flex-wrap items-center cursor-pointer focus-visible:outline-none rounded group-open:rounded-b-none relative text-fgray-300 ">
              <div className="group-open:rotate-180 transition duration-300">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M7 14.5L11.6464 9.85355C11.8417 9.65829 12.1583 9.65829 12.3536 9.85355L17 14.5"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  ></path>
                </svg>
              </div>
              <div>
                <h3 className="font-medium text-left">
                  {t("sidebar.room.title")} ({roomCount && roomCount})
                </h3>
              </div>
            </summary>
            <ul className="open:transition open:duration-300">
              {roomList ? (
                roomList.map(({ id, displayName, description }) => (
                  <li
                    className={
                      `rounded nav-item px-4 py-3 mx-4 list-none items-center cursor-pointer  hover:font-medium font-normal active:rounded ` +
                      (filter === id
                        ? "bg-forange-400 text-white font-medium"
                        : " hover:text-forange-400 ")
                    }
                    key={id}
                    onClick={(e) => {
                      e.preventDefault();
                      handleToggleFilter(id);
                    }}
                  >
                    {t(displayName)}
                  </li>
                ))
              ) : (
                <LoadingSidebar />
              )}
              {unassigned && roomList && (
                <li
                  className={
                    `rounded nav-item px-4 py-3 mx-4 list-none items-center cursor-pointer  hover:font-medium font-normal active:rounded ` +
                    (filter === "No_Room"
                      ? "bg-forange-400 text-white font-medium"
                      : " hover:text-forange-400 ")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    handleToggleFilter("No_Room");
                  }}
                >
                  {t("sidebar.no_room")} (
                  {unassignedRoomCount && unassignedRoomCount})
                </li>
              )}
            </ul>
          </details>
        </ul>
      </nav>
    </>
  );
}

export default Sidebar;
