import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ActionButton from "../layout/buttons/ActionButton";
import { ReactComponent as ResetIcon } from "../icons/reset.svg";
import { ReactComponent as BroomIcon } from "../icons/broom.svg";
import LoadingText from "../layout/loading/LoadingText";
import { postApi, getApi } from "../../tools/axiosInstances";
import { PortalContext } from "../../PortalContext";
import ResetPolicyManagementButtons from "./policyManagement/ResetPolicyManagementButtons";
import CleanPolicyManagementButton from "./policyManagement/CleanPolicyManagementButton";
function PolicyManagementInfo() {
  const { t } = useTranslation("global");
  const { ipadPOnboarded, setWindowsPOnboarded, setIpadOnboarded } =
    useContext(PortalContext);
  const [resetWindowsLoading, setResetWindowsLoading] = useState(false);
  const [resetIpadsLoading, setResetIpadsLoading] = useState(false);
  const [cleanLoading, setCleanLoading] = useState(false);
  const [nonSmpPolicies, setNonSmpPolicies] = useState(() => {
    return sessionStorage.getItem("policyCount_all") || -1;
  });
  useEffect(() => {
    getApi(`/policy/online/all/`).then((res) => {
      let nonSMP = 0;
      for (let policy = 0; policy < res.data.length; policy++) {
        const element = res.data[policy];
        if (
          !["SMP_P", "SMP_KP", "SMP_PK"].some((v) =>
            element.displayName.includes(v)
          )
        ) {
          nonSMP++;
        }
      }
      setNonSmpPolicies(nonSMP);
      sessionStorage.setItem(`policyCount_all`, nonSMP);
    });
  }, []);
  

  return (
    <>
      <div className="border-b-2 border-gray-300 space-y-4 py-8">
        <p className="font-bold text-xl">
          {t("pages.settings.policy.reset.title")}
        </p>
        <p className="text-fgray-400 font-normal">
          {t("pages.settings.policy.reset.body")}
        </p>
       <ResetPolicyManagementButtons/>
      </div>
      <div className=" space-y-4 py-8">
        <p className="font-bold text-xl">
          {t("pages.settings.policy.clean.title")}
        </p>
        <p className="text-fgray-400 font-normal">
          {t("pages.settings.policy.clean.body")}
        </p>
        <p className="text-fgray-400 font-normal">
          {nonSmpPolicies >= 0 ? (
            t("pages.settings.policy.clean.unused", { count: nonSmpPolicies })
          ) : (
            <LoadingText />
          )}
        </p>
        <CleanPolicyManagementButton/>
      </div>
    </>
  );
}

export default PolicyManagementInfo;
