import React, { useEffect, useState } from "react";
import PageTitle from "../components/layout/PageTitle";
import { useTranslation } from "react-i18next";
import PageContent from "../components/layout/PageContent";
import { useCheckLicenseStatus } from "../tools/utilties";
import LoadingTable from "../components/layout/loading/LoadingTable";
import PrinterTable from "../components/printer/PrinterTable";
import { getApi } from "../tools/axiosInstances";
import RefreshButton from "../components/layout/buttons/RefreshButton";
import { ReactComponent as UnmanageLogo } from "../components/icons/unmanage.svg";
import { ReactComponent as UninstallLogo } from "../components/icons/uninstall.svg";
import { ReactComponent as InstallLogo } from "../components/icons/install.svg";
import { ReactComponent as RemoveLogo } from "../components/icons/remove.svg";
import { ReactComponent as AddLogo } from "../components/icons/add.svg";
import PrinterActionButton from "../components/printer/PrinterActionButton";
import Sidebar from "../components/layout/Sidebar";
import { useNavigate } from "react-router-dom";
import ActionButton from "../components/layout/buttons/ActionButton";
import MoreButtons from "../components/layout/buttons/MoreButtons";
import { ValidateRole } from "../tools/utilties";
function Printer() {
  const { t } = useTranslation("global");
  const [loadingr, setLoadingr] = useState(false);
  const [printersData, setPrintersData] = useState([]);
  const [reload, setReload] = useState(0);
  const [selected, setSelected] = useState([]);
  const [columnList, setColumnList] = useState([]);
  const [showFilter, setShowFilter] = useState([]);
  const [clear, setClear] = useState(0);
  const [filter, setFilter] = useState("99999999-9999-9999-9999-999999999999");
  const handleReload = () => setReload((p) => p + 1);
  const clearSelected = () => setClear((p) => p + 1);
  const navigate = useNavigate();
  useEffect(() => {
    setLoadingr(true);
    setPrintersData(null);
    getApi(`/printer/status/${filter}`, {}).then((list) => {
      setPrintersData(list.data);
      setLoadingr(false);
    });
  }, [filter, reload]);
  useCheckLicenseStatus();
  useEffect(() => {
    ValidateRole();
  }, []);
  return (
    <>
      {/* TitleBar */}
      <PageTitle title={t("pages.printer.title")}>
        <RefreshButton loading={loadingr} handleRefresh={handleReload} />
        <PrinterActionButton
          kind="usg"
          target="pri"
          title={"actions.unassign"}
          selected={selected}
          Icon={UninstallLogo}
          clear={clearSelected}
          filter={filter}
        />
        <PrinterActionButton
          kind="asg"
          target="pri"
          title={"actions.assign"}
          selected={selected}
          Icon={InstallLogo}
          clear={clearSelected}
          filter={filter}
        />
        <ActionButton
          Icon={AddLogo}
          onClick={() => navigate("00000000-0000-0000-0000-000000000000")}
          subtle
        >
          {t("actions.add")}
        </ActionButton>
        <MoreButtons free>
          <PrinterActionButton
            kind="del"
            target="pri"
            title={"pages.application.delete"}
            selected={selected}
            Icon={RemoveLogo}
            clear={clearSelected}
            filter={filter}
            more
          />
          <PrinterActionButton
            kind="unm"
            target="pri"
            title={"actions.unmanage"}
            selected={selected}
            Icon={UnmanageLogo}
            clear={clearSelected}
            filter={filter}
            more
          />
        </MoreButtons>
      </PageTitle>
      <PageContent>
        <div className="min-w-64 justify-end mr-5 mb-5 overflow-y-auto">
          <Sidebar
            filter={filter}
            setFilter={setFilter}
            globalId="99999999-9999-9999-9999-999999999999"
          />
        </div>
        <div className="max-w-[calc(100%-calc((100%-72rem)/2)-18rem)] h-full">
          {loadingr ? (
            <LoadingTable />
          ) : (
            <PrinterTable
              printerData={printersData}
              setSelected={setSelected}
              filter={filter}
              showColumns={columnList}
              clear={clear}
            />
          )}
        </div>
      </PageContent>
    </>
  );
}

export default Printer;
