import React from "react";

function PageContent({ children }) {
  return (
    <>
      <div className="mx-auto p-5 flex h-[calc(100vh-12rem)] overflow-hidden ">
        <div className="min-w-[calc((100%-72rem)/2)]"></div>
        <div className="flex w-full">{children}</div>
      </div>
    </>
  );
}

export default PageContent;
