import React,{useState} from 'react'

function PasswordBox({ definition, mode, profileId }) {
const [valid, setValid] = useState(true);
  const [textValue, setTextValue] = useState(definition.selected || "");
  const [boxType, setBoxType] = useState(()=>definition.selected ? true:false)
  function handleChange(e) {
    if (e.target.value != definition.selected) {
      // if the value is the same as the selected, do nothing
      setTextValue(e.target.value);
      if (e.target.value.match(definition.validator) != null) {
        setValid(true);
        const input = document.getElementById(profileId)
        Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'checked').set.call(input, true);
        input.dispatchEvent(new Event("click", { bubbles: true }));
      } else {
        setValid(false);
        const input = document.getElementById(profileId)
        Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'checked').set.call(input, false);
        input.dispatchEvent(new Event("click", { bubbles: true }));
      }
    }
  }
  const onFocusHandler = ()=>{
    setTextValue("")
    setBoxType(false)
  }
  return (
    <input
      type={boxType ? "password":"text"}
      name={definition.name}
      id={definition.id}
      required
      placeholder={definition.hint}
      className={`p-2 mx-4 my-2 w-full flex-1 text-sm rounded-lg border border-fgray-400 ${
        valid ? "" : " bg-red-200 text-red-800 "
      } `}
      onChange={handleChange}
      disabled={!mode}
      value={textValue}
      onFocus={onFocusHandler}
    />
  )
}

export default PasswordBox