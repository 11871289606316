import React, { useState, useEffect, useContext } from "react";
import { getApi } from "../tools/axiosInstances";
import { useTranslation } from "react-i18next";
import SidebarDevice from "../components/device/SidebarDevice";
import DeviceTable from "../components/device/DeviceTable";
import DeviceActionModal from "../components/device/DeviceActionModal";
import { isEqual } from "lodash";
import DeviceAssignModal from "../components/device/DeviceAssignModal";
import LoadingTable from "../components/layout/loading/LoadingTable";
import RefreshButton from "../components/layout/buttons/RefreshButton";
import DeviceModeBar from "../components/layout/DeviceModeBar";
import { PortalContext } from "../PortalContext";
import { ReactComponent as PasscodeLogo } from "../components/icons/lock-open.svg";
import { ReactComponent as SyncLogo } from "../components/icons/sync.svg";
import { ReactComponent as RebootLogo } from "../components/icons/reboot.svg";
import { ReactComponent as ResetLogo } from "../components/icons/reset.svg";
import { ReactComponent as RemoveLogo } from "../components/icons/remove.svg";
import { ReactComponent as AssignLogo } from "../components/icons/assign.svg";
import { ReactComponent as AddLogo } from "../components/icons/add.svg";
import ActionButton from "../components/layout/buttons/ActionButton";
import MoreButtons from "../components/layout/buttons/MoreButtons";
import IpadOnboarding from "../components/device/IpadOnboarding";
import DeviceAssignUnenrolledModal from "../components/device/DeviceAssignUnenrolledModal";
import { useCheckLicenseStatus } from "../tools/utilties";
import { useNavigate } from "react-router-dom";
function Device({ refresh }) {
  const { deviceMode, ipadOnboarded } = useContext(PortalContext);
  const navigate = useNavigate();
  const { t } = useTranslation("global");
  const [filter, setFilter] = useState("99999999-9999-9999-9999-999999999999");
  const [selected, setSelected] = useState([]);
  const [sync, setSync] = useState(false);
  const [reboot, setReboot] = useState(false);
  const [reset, setReset] = useState(false);
  const [remove, setRemove] = useState(false);
  const [assign, setAssign] = useState(false);
  const [assignUnenrolled, setAssignUnenrolled] = useState(false);
  const [resetPasscode, setResetPasscode] = useState(false);
  const [reload, setReload] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deviceEnrolled, setDeviceEnrolled] = useState(true);
  const [mixed, setMixed] = useState(false);
  const [unenrolled, setUnenrolled] = useState(false);
  const [columnFilterBar, setColumnFilterBar] = useState(false);
  const userRole = sessionStorage.getItem("role");
  const [columnList, setColumnList] = useState([]); //[{id:"deviceCategoryDisplayName",visible:true},{id:"roomDisplayName",visible:true},{id:"lastSyncDateTime",visible:true},{id:"model",visible:true},{id:"operatingSystem",visible:true},{id:"serialNumber",visible:true},{id:"manufacturer",visible:true}])
  const [windowsData, setWindowsData] = useState(() => {
    const winDevices = JSON.parse(sessionStorage.getItem("windowsData"));
    !winDevices && setLoading(true);
    return winDevices || null;
  });
  const [ipadData, setIpadData] = useState(() => {
    const ipadDevices = JSON.parse(sessionStorage.getItem("ipadData"));
    !ipadDevices && setLoading(true);
    return ipadDevices || null;
  });
  const [unenrolledData, setUnenrolledData] = useState(() => {
    const unenrolledDevices = JSON.parse(
      sessionStorage.getItem("unenrolledData")
    );
    !unenrolledDevices && setLoading(true);
    return unenrolledDevices || null;
  });
  const handleReload = () => {
    setReload((p) => p + 1);
    setLoading(true);
  };
  const handleColumnListInitialize = (columns) => {
    let initializedColumnList = [];
    columns.map((column) => {
      if (column.columnDef.canHide) {
        initializedColumnList.push({
          id: column.id,
          label: column.columnDef.label,
          visible: column.getIsVisible(),
        });
      }
    });
    setColumnList(initializedColumnList);
  };
  const handleColumnListChange = (e) => {
    let newColumnList = [];
    columnList.map((column) => {
      if (column.id === e.id) {
        column.visible = e.checked;
      }
      newColumnList.push(column);
    });
    setColumnList(newColumnList);
  };
  useCheckLicenseStatus();
  useEffect(() => {
    getApi("/device/windows", {}).then((list) => {
      if (
        !isEqual(
          JSON.stringify(list.data),
          sessionStorage.getItem("windowsData")
        )
      ) {
        setWindowsData(list.data);
        sessionStorage.setItem("windowsData", JSON.stringify(list.data));
        list.data.map((device) => {
          sessionStorage.setItem(device.id, JSON.stringify(device));
        });
      }
      setLoading(false);
    });
    getApi("/device/ipad", {}).then((list) => {
      if (
        !isEqual(JSON.stringify(list.data), sessionStorage.getItem("ipadData"))
      ) {
        setIpadData(list.data);
        sessionStorage.setItem("ipadData", JSON.stringify(list.data));
        list.data.map((device) => {
          sessionStorage.setItem(device.id, JSON.stringify(device));
        });
      }
      setLoading(false);
    });
    getApi("/device/unenrolled/", {}).then((list) => {
      if (
        !isEqual(
          JSON.stringify(list.data),
          sessionStorage.getItem("unenrolledData")
        )
      ) {
        setUnenrolledData(list.data);
        sessionStorage.setItem("unenrolledData", JSON.stringify(list.data));
        list.data.map((device) => {
          sessionStorage.setItem(device.id, JSON.stringify(device));
        });
      }
      setLoading(false);
    });
  }, [reload]);

  const clear = () => {
    // tableRef.current.clearSelected();
  };
  useEffect(() => {
    let enrolled = true;
    let mix = false;
    selected.map((device) => {
      enrolled = enrolled && !device.original.targetType.includes("unenrolled");
      if (mix) {
        if (mix !== device.original.targetType) {
          setMixed(true);
        }
      } else {
        mix = device.original.targetType;
        setMixed(false);
      }
    });
    setDeviceEnrolled(enrolled);
  }, [selected]);

  function toggleSync() {
    if (selected.length > 0) {
      setSync(!sync);
    }
  }
  function toggleReboot() {
    if (selected.length > 0) {
      setReboot(!reboot);
    }
  }
  function toggleAssignUnenrolled() {
    setAssignUnenrolled(!assignUnenrolled);
  }
  function toggleReset() {
    if (selected.length > 0) {
      setReset(!reset);
    }
  }
  function toggleRemove() {
    if (selected.length > 0) {
      setRemove(!remove);
    }
  }
  function toggleAssign() {
    if (selected.length > 0) {
      setAssign(!assign);
    }
  }
  function toggleResetPasscode() {
    if (selected.length > 0) {
      setResetPasscode(!resetPasscode);
    }
  }
  const enrolledButtons = (
    <>
      <ActionButton
        id="sync"
        Icon={SyncLogo}
        onClick={toggleSync}
        tooltip={`tooltip.device.${deviceMode}.sync`}
        subtle
      >
        {t("pages.device.sync")}
      </ActionButton>

      {deviceMode === "windows" && (
        <ActionButton
          id="reset"
          Icon={ResetLogo}
          onClick={toggleReset}
          tooltip={`tooltip.device.${deviceMode}.reset`}
          subtle
        >
          {t("pages.device.reset")}
        </ActionButton>
      )}

      {deviceMode === "ipad" && (
        <ActionButton
          id="passcode"
          Icon={PasscodeLogo}
          onClick={toggleResetPasscode}
          tooltip={`tooltip.device.${deviceMode}.passcode`}
          subtle
        >
          {t("pages.device.passcode")}
        </ActionButton>
      )}
      <ActionButton
        id="assign"
        Icon={AssignLogo}
        onClick={toggleAssign}
        tooltip={`tooltip.device.${deviceMode}.assign`}
        subtle
      >
        {t("pages.device.assign")}
      </ActionButton>
      <MoreButtons>
        {deviceMode === "ipad" ? (
          <ActionButton
            id="reset"
            Icon={ResetLogo}
            onClick={toggleReset}
            tooltip={`tooltip.device.${deviceMode}.reset`}
            position="bottom"
          >
            {t("pages.device.reset")}
          </ActionButton>
        ) : null}
        <ActionButton
          id="reboot"
          Icon={RebootLogo}
          onClick={toggleReboot}
          tooltip={`tooltip.device.${deviceMode}.reboot`}
          position={"bottom"}
        >
          {t("pages.device.reboot")}
        </ActionButton>
        {userRole === "smp.administrator" && (
          <ActionButton
            id="remove"
            Icon={RemoveLogo}
            onClick={toggleRemove}
            tooltip={`tooltip.device.${deviceMode}.remove`}
            position={"bottom"}
          >
            {t("pages.device.remove")}
          </ActionButton>
        )}
        {deviceMode === "windows" && (
          <ActionButton
            id="adddevice"
            Icon={AddLogo}
            onClick={() => navigate("/adddevice")}
            tooltip={`tooltip.device.${deviceMode}.add`}
            position={"bottom"}
          >
            {t("pages.device.add")}
          </ActionButton>
        )}
      </MoreButtons>
    </>
  );

  const unenrolledButtons = (
    <>
      <ActionButton
        id="remove"
        Icon={RemoveLogo}
        onClick={toggleRemove}
        tooltip={`tooltip.device.${deviceMode}.remove`}
        position={"bottom"}
        subtle
      >
        {t("pages.device.remove")}
      </ActionButton>
      <ActionButton
        id="assignUnenrolled"
        Icon={AssignLogo}
        onClick={toggleAssignUnenrolled}
        tooltip={`tooltip.device.unenrolled.${deviceMode}.assign`}
        subtle
      >
        {t("pages.device.assign")}
      </ActionButton>
    </>
  );
  return (
    <>
      {/*Title Bar*/}
      <div className="w-full shadow-sm">
        <div className="mx-auto max-w-6xl pt-11 pb-4 px-4 flex justify-between ">
          <div className="text-2xl font-semibold">
            {t("pages.device.title")}
          </div>
          <div className="justify-end flex">
            <RefreshButton loading={loading} handleRefresh={handleReload} />
            {!mixed && (!unenrolled ? enrolledButtons : unenrolledButtons)}
          </div>
        </div>
      </div>
      {/* Content */}

      <div className="mx-auto p-5 flex h-[calc(100vh-12rem)] overflow-hidden ">
        <div className="min-w-[calc((100%-72rem)/2)]"></div>
        <div className="min-w-64 justify-end mr-5 mb-5 overflow-y-auto">
          <SidebarDevice
            unassigned="True"
            filter={filter}
            setFilter={setFilter}
            globalId="99999999-9999-9999-9999-999999999999"
            // deviceData={deviceData}
            refresh={refresh}
          />
        </div>
        <div className="max-w-[calc(100%-calc((100%-72rem)/2)-18rem)] h-full">
          <div className="w-[52rem] z-20 sticky">
            <DeviceModeBar
              unenrolled={setUnenrolled}
              setFilter={setColumnFilterBar}
              columns={columnList}
              setColumns={handleColumnListChange}
              target="device"
            />
          </div>
          <div className="h-full">
            {loading ? (
              <LoadingTable />
            ) : unenrolled ? (
              unenrolledData ? (
                <DeviceTable
                  deviceData={unenrolledData}
                  setSelected={setSelected}
                  reload={refresh}
                  filter={filter}
                  showColumns={columnList}
                  setShowColumns={handleColumnListInitialize}
                  showFilter={columnFilterBar}
                  dataType="unenrolled"
                />
              ) : (
                <LoadingTable />
              )
            ) : deviceMode === "windows" ? (
              windowsData ? (
                <DeviceTable
                  deviceData={windowsData}
                  setSelected={setSelected}
                  reload={refresh}
                  filter={filter}
                  showColumns={columnList}
                  setShowColumns={handleColumnListInitialize}
                  showFilter={columnFilterBar}
                  dataType="windows"
                />
              ) : (
                <LoadingTable />
              )
            ) : deviceMode === "ipad" ? (
              ipadOnboarded === "pending" || ipadOnboarded === false ? (
                <IpadOnboarding target="device" />
              ) : ipadData ? (
                <DeviceTable
                  deviceData={ipadData}
                  setSelected={setSelected}
                  reload={refresh}
                  filter={filter}
                  showColumns={columnList}
                  setShowColumns={handleColumnListInitialize}
                  showFilter={columnFilterBar}
                  dataType="ipads"
                />
              ) : (
                <LoadingTable />
              )
            ) : (
              <LoadingTable />
            )}
          </div>
        </div>
      </div>

      {sync && (
        <DeviceActionModal
          action="sync"
          selected={selected}
          toggle={toggleSync}
          clear={clear}
        />
      )}
      {reboot && (
        <DeviceActionModal
          action="reboot"
          selected={selected}
          toggle={toggleReboot}
          clear={clear}
        />
      )}
      {reset && (
        <DeviceActionModal
          action="reset"
          selected={selected}
          toggle={toggleReset}
          clear={clear}
        />
      )}
      {remove && (
        <DeviceActionModal
          action="remove"
          selected={selected}
          toggle={toggleRemove}
          clear={clear}
        />
      )}
      {resetPasscode && (
        <DeviceActionModal
          action="resetPasscode"
          selected={selected}
          toggle={toggleResetPasscode}
          clear={clear}
        />
      )}
      {assignUnenrolled && (
        <DeviceAssignUnenrolledModal
          action="assign"
          selected={selected}
          showModal={assignUnenrolled}
          toggle={toggleAssignUnenrolled}
          clear={clear}
        />
      )}
      {assign && (
        <DeviceAssignModal
          action="assign"
          selected={selected}
          showModal={assign}
          toggle={toggleAssign}
          clear={clear}
        />
      )}
      {/* {taskId ? <GroupProgress taskId={taskId} setTaskId={setTaskId} reload={setReload} /> :""} */}
    </>
  );
}

export default Device;
