import React from "react";
import { useTranslation } from "react-i18next";
import Field from "../layout/design/Field";
import BitlockerFields from "./BitlockerFields";
import { ReactComponent as LockIcon } from "../icons/lock-closed.svg";
import { ReactComponent as LockOpenIcon } from "../icons/lock-open.svg";

function DeviceSecurityBox({ deviceInfo, bitlocker }) {
  const { t } = useTranslation("global");
  return (
    <>
      <div className="w-full space-y-5 rounded-lg px-5 py-4">
        <div className="w-full py-4 flex">
          <p className="mr-2">{t("pages.device_detail.device_status")}</p>
          {deviceInfo.isEncrypted ? (
            <>
              <LockIcon className="mr-2 w-6 h-6" />
              {t("pages.device_detail.encrypted")}
            </>
          ) : (
            <>
              <LockOpenIcon className="mr-2 w-6 h-6" />
              {t("pages.device_detail.unencrypted")}
            </>
          )}
        </div>
        {bitlocker != [] && <BitlockerFields bitlockerKeys={bitlocker} />}
      </div>
    </>
  );
}

export default DeviceSecurityBox;
