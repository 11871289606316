import React, { useState, useEffect } from "react";
import { getApi } from "../../tools/axiosInstances";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";
import LoadingSidebar from "../layout/loading/LoadingSidebar";

function SidebarDevice({ unassigned, filter, setFilter, globalId, refresh }) {
  const { t } = useTranslation("global");
  const [categoryList, setCategoryList] = useState(() => {
    const allCategories = JSON.parse(sessionStorage.getItem("allCategories"));
    return allCategories || false;
  });
  const [roomList, setRoomList] = useState(() => {
    const allRooms = JSON.parse(sessionStorage.getItem("allRooms"));
    return allRooms || false;
  });
  const [roomCount, setRoomCount] = useState(() => {
    return (unassigned ? roomList.length + 1 : roomList.length) || 0;
  });
  const [categoryCount, setCategoryCount] = useState(() => {
    return (unassigned ? categoryList.length + 1 : categoryList.length) || 0;
  });

  // useEffect(()=>{

  // },[deviceData])

  useEffect(() => {
    getApi("/room/", {}).then((list) => {
      if (
        !isEqual(
          JSON.stringify(list.data["value"]),
          sessionStorage.getItem("allRooms")
        )
      ) {
        setRoomList(list.data["value"]);
        sessionStorage.setItem("allRooms", JSON.stringify(list.data["value"]));
      }
      unassigned
        ? setRoomCount(list.data["value"].length + 1)
        : setRoomCount(list.data["value"].length);
    });
    getApi("/category/", {}).then((list) => {
      // if (! categoryList){
      // if (! isEqual(list.data,categoryList)){
      // if (! isEqual(JSON.stringify(list.data),sessionStorage.getItem("allCategories"))){
      setCategoryList(list.data);
      sessionStorage.setItem("allCategories", JSON.stringify(list.data));
      // }
      unassigned
        ? setCategoryCount(list.data.length + 1)
        : setCategoryCount(list.data.length);
    });
  }, [refresh]);

  function handleToggleFilter(name) {
    setFilter(name);
  }

  return (
    <>
      <nav className=" mr-5 w-64 mb-12 ">
        <ul className="nav flex flex-col w-full">
          {/* allDevices */}
          <li
            className={
              `rounded nav-item px-4 py-3 mx-4 list-none items-center  hover:font-medium fornt-normal  active:rounded flex  justify-between cursor-pointer ` +
              (filter === globalId
                ? "bg-forange-400 text-white font-medium"
                : " hover:text-forange-400 ")
            }
            key={globalId}
            aria-current="page"
            onClick={(e) => {
              e.preventDefault();
              handleToggleFilter(globalId);
            }}
          >
            {t("sidebar.all_devices")}
            {/* <p className="bg-fgray-200 text-fblack rounded-lg px-3 py-2 text-xs ">{deviceData ? deviceData.length : 0}</p> */}
          </li>
          {/* Categories */}
          <details
            key="catGroup"
            className="group open:transition open:duration-300"
            open
          >
            <summary
              key="sumRoom"
              className="px-4 py-3 mx-4 list-none flex flex-wrap items-center cursor-pointer focus-visible:outline-none rounded group-open:rounded-b-none relative text-fgray-300 "
            >
              <div className=" group-open:rotate-180 transition duration-300">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M7 14.5L11.6464 9.85355C11.8417 9.65829 12.1583 9.65829 12.3536 9.85355L17 14.5"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  ></path>
                </svg>
              </div>
              <div>
                <h3 className="font-medizm text-left">
                  {t("sidebar.category.title")} ({categoryCount})
                </h3>
              </div>
            </summary>
            <ul className="open:transition open:duration-300">
              {categoryList ? (
                categoryList.map(({ id, displayName, shared }) => (
                  <li
                    className={
                      `rounded nav-item px-4 py-3 mx-4 list-none items-center cursor-pointer  hover:font-medium font-normal  active:rounded justify-between flex ` +
                      (filter === id
                        ? "bg-forange-400 text-white font-medium"
                        : " hover:text-forange-400 ")
                    }
                    key={id}
                    onClick={(e) => {
                      e.preventDefault();
                      handleToggleFilter(id);
                    }}
                  >
                    <p>
                      {shared
                        ? t(
                            `categories.name.${displayName}`,
                            `${displayName.substring(7)} ${t(
                              "categories.name.shared"
                            )}`
                          )
                        : t(`categories.name.${displayName}`, displayName)}
                    </p>
                    {/* <p className="bg-fgray-200 text-fblack rounded-lg px-3 py-2 text-xs ">{count}</p> */}
                  </li>
                ))
              ) : (
                <LoadingSidebar />
              )}
              {unassigned && categoryList && (
                <li
                  className={
                    `rounded nav-item px-4 py-3 mx-4 list-none items-center cursor-pointer hover:font-medium font-normal  active:rounded justify-between flex ` +
                    (filter === "00000000-0000-0000-0000-000000000099"
                      ? "bg-forange-400 text-white font-medium"
                      : " hover:text-forange-400 ")
                  }
                  key="00000000-0000-0000-0000-000000000099"
                  onClick={(e) => {
                    e.preventDefault();
                    handleToggleFilter("00000000-0000-0000-0000-000000000099");
                  }}
                >
                  {t("sidebar.no_category")}
                  {/* <p className="bg-fgray-200 text-fblack rounded-lg px-3 py-2 text-xs ">{unassignedCategoryCount && unassignedCategoryCount}</p> */}
                </li>
              )}
            </ul>
          </details>
          {/* Rooms */}
          <details
            key="romGroup"
            className="group open:transition open:duration-300"
            open
          >
            <summary className="px-4 py-3 mx-4 list-none flex flex-wrap items-center cursor-pointer focus-visible:outline-none rounded group-open:rounded-b-none relative text-fgray-300 ">
              <div className="group-open:rotate-180 transition duration-300">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M7 14.5L11.6464 9.85355C11.8417 9.65829 12.1583 9.65829 12.3536 9.85355L17 14.5"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  ></path>
                </svg>
              </div>
              <div>
                <h3 className="font-medium text-left">
                  {t("sidebar.room.title")} ({roomCount && roomCount})
                </h3>
              </div>
            </summary>
            <ul className="open:transition open:duration-300">
              {roomList ? (
                roomList.map(({ id, displayName, description, count }) => (
                  <li
                    className={
                      `rounded nav-item px-4 py-3 mx-4 list-none items-center cursor-pointer hover:font-medium font-normal justify-between flex active:rounded ` +
                      (filter === id
                        ? "bg-forange-400 text-white font-medium"
                        : " hover:text-forange-400 ")
                    }
                    key={id}
                    onClick={(e) => {
                      e.preventDefault();
                      handleToggleFilter(id);
                    }}
                  >
                    {displayName}
                    {/* <p className="bg-fgray-200 text-fblack rounded-lg px-3 py-2 text-xs ">{count}</p> */}
                  </li>
                ))
              ) : (
                <LoadingSidebar />
              )}
              {unassigned && roomList && (
                <li
                  className={
                    `rounded nav-item px-4 py-3 mx-4 list-none items-center cursor-pointer hover:font-medium font-normal  active:rounded  justify-between flex ` +
                    (filter === "00000000-0000-0000-0000-000000000088"
                      ? "bg-forange-400 text-white font-medium"
                      : " hover:text-forange-400 ")
                  }
                  key="00000000-0000-0000-0000-000000000088"
                  onClick={(e) => {
                    e.preventDefault();
                    handleToggleFilter("00000000-0000-0000-0000-000000000088");
                  }}
                >
                  {t("sidebar.no_room")}
                  {/* <p className="bg-fgray-200 text-fblack rounded-lg px-3 py-2 text-xs ">{unassignedRoomCount && unassignedRoomCount}</p> */}
                </li>
              )}
            </ul>
          </details>
        </ul>
      </nav>
    </>
  );
}

export default SidebarDevice;
