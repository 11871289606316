import React, { useState, useContext, useEffect } from "react";
import BaseButton from "../layout/buttons/BaseButton";
import { useTranslation } from "react-i18next";
import { PortalContext } from "../../PortalContext";
import { Link } from "react-router-dom";
import TaskDescription from "./TaskDescription";
function NotificationCenter() {
  // const taskOnProgress = [{"id":2181,"start_time":"2024-05-30T13:24:03.162487+02:00","end_time":null,"description":null,"status":null,"progress":0.0,"detail":[{"deviceId":"1b121b48-ed06-4749-886b-9d7432ae220d"}],"kind":"sync","target":"device","display":{"amount":1,"device":"DESKTOP-HQSIUM9"},"results":{},"state":"new","last_updated":"2024-05-30T13:24:03.162863+02:00","requester":"Intune-Dev.Marcelo@thsb.onmicrosoft.com"},{"id":2181,"start_time":"2024-05-30T13:24:03.162487+02:00","end_time":null,"description":null,"status":null,"progress":0.0,"detail":[{"deviceId":"1b121b48-ed06-4749-886b-9d7432ae220d"}],"kind":"onboard","target":"policy","display":{"amount":1,"device":"DESKTOP-HQSIUM9"},"results":{},"state":"new","last_updated":"2024-05-30T13:24:03.162863+02:00","requester":"Intune-Dev.Marcelo@thsb.onmicrosoft.com"}]
  const { taskOnProgress } = useContext(PortalContext);
  const [toastList, setToastList] = useState([]);
  useEffect(() => {
    taskOnProgress.map((task) =>
      toastList.find((e) => e.id === task.id) ? (
        <></>
      ) : (
        setToastList((p) => [task, ...p])
      )
    );
  }, [taskOnProgress]);
  const handlePopToast = (task) => {
    setToastList((p) => p.filter((f) => f.id != task.id));
  };
  return (
    <>
      <div className="fixed bottom-[72px] left-4 space-y-4 z-50 transition-opacity duration-1000">
        {toastList.map((task, index) => (
          <ToastCard task={task} key={`toast_${index}`} pop={handlePopToast} />
        ))}
      </div>
    </>
  );
}
function ToastCard({ task, pop }) {
  const [display, setDisplay] = useState(true);
  const { t } = useTranslation("global");
  useEffect(() => {
    const timerDisplay = setTimeout(() => {
      setDisplay(false);
      pop && pop(task);
    }, 3000);

    return () => {
      clearTimeout(timerDisplay);
    };
  }, []);
  return (
    <>
      {display && (
        <div className={`bg-fwhite w-[400px] rounded p-4  flex shadow `}>
          <p className="w-[60%] items-center flex text-sm line-clamp-3 text-fgray-400">
            {Object.keys(task.results).length > 0 || ['printer'].includes(task.target) ? (
              t(
                `tasks.${task.kind}.${task.target}`,
                `tasks.${task.kind}.${task.target}`,
                task.display
              )
            ) : (
              <TaskDescription task={task} />
            )}
          </p>
          <p className="w-[40%] items-center flex">
            <Link to="/progress">
              <BaseButton className="w-full" subtle>
                {t("task.toast.view_history")}
              </BaseButton>
            </Link>
          </p>
        </div>
      )}
    </>
  );
}

export default NotificationCenter;
