import React, { useState, useEffect } from "react";
import { getApi } from "../../tools/axiosInstances";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";

function RoomCombo({ setSelected, includeUnassigned }) {
  const { t } = useTranslation("global");
  const [roomList, setRoomList] = useState(() => {
    const allRooms = JSON.parse(sessionStorage.getItem("allRooms"));
    return allRooms || false;
  });

  useEffect(() => {
    
        getApi("/room/", {}).then(
          (list) => {
            if (
              !isEqual(
                JSON.stringify(list.data["value"]),
                sessionStorage.getItem("allRooms")
              )
            ) {
              setRoomList(list.data["value"]);
              sessionStorage.setItem(
                "allRooms",
                JSON.stringify(list.data["value"])
              );
            }
          }
        );
  }, []);
  function handleChange(event) {
    setSelected(event.target.value);
  }
  return (
    <select
      name="Rooms"
      id="rooms"
      onChange={handleChange}
      defaultValue={t("rooms.modal.choose")}
      className="w-full rounded-xl border border-fgray-300 py-2 px-4 text-fblack"
    >
      <option value="0">{t("rooms.modal.choose")}</option>
      {roomList &&
        roomList.map(({ id, displayName }) => (
          <option value={id} key={`rom_${id}`}>
            {displayName}
          </option>
        ))}
      {includeUnassigned && (
        <option
          value={"00000000-0000-0000-0000-000000000000"}
          key={"rom_00000000-0000-0000-0000-000000000000"}
        >
          {t("rooms.name.no_room")}
        </option>
      )}
    </select>
  );
}

export default RoomCombo;
