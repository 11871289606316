import React,{useState} from 'react'
import { getApi } from "../../tools/axiosInstances";
import { useInterval } from "../../tools/utilties";

function GroupProgress({taskId,setTaskId,makeRefresh}) {
  const [progress, setProgress] = useState(0);
  useInterval(() => {
    getApi(`/tasks/${taskId}/`, {}).then((response) => {
      setProgress(response.data.progress);
    });
    if (progress == 100){
      setTaskId(false);
      makeRefresh && makeRefresh(prev => prev + 1);
    }
  }, 5000);

  return (
    <></>
  )
}

export default GroupProgress