import "./App.css";
// import "@material-tailwind/react/tailwind.css";
import Home from "./pages/home";
import Application from "./pages/application";
import Appstore from "./pages/appstore";
import Policy from "./pages/policy";
import Progress from "./pages/progress";
import Login from "./pages/login";
import axios from "axios";
import Group from "./pages/group";
import Device from "./pages/device";
import Navbar from "./components/topbar/Navbar";
import Footer from "./components/Footer";
import FirstOnboarding from "./components/authentication/FirstOnboarding";
import TermsAndConditions from "./pages/termsAndConditions";
import Settings from "./pages/settings";
import { Route, Routes } from "react-router-dom";
import DeviceDetail from "./pages/deviceDetail";
import { useState, useEffect } from "react";
import AdminAuthorization from "./components/authentication/AdminAuthorization";
import AdminConsent from "./components/authentication/AdminConsent";
import LoadingHome from "./components/layout/loading/LoadingHome";
import { PortalContext } from "./PortalContext";
import DataPrivacy from "./pages/dataPrivacy";
import HeaderLogo from "./components/topbar/HeaderLogo";
import { Providers, ProviderState } from "@microsoft/mgt-element";
import { useTranslation } from "react-i18next";
import AddDevice from "./pages/adddevice";
import Printer from "./pages/printer";
import PrinterDetail from "./pages/printerDetail";

function App() {
  // const { instance, accounts } = useMsal();
  // const isAuthenticated = useIsAuthenticated();
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const auth = JSON.parse(sessionStorage.getItem("isAuthenticated"));
    return (auth && auth) || false;
  });
  const [isAdminConsent, setIsAdminConsent] = useState(() => {
    const apiAuth = JSON.parse(sessionStorage.getItem("apiAuth"));
    return (apiAuth && apiAuth.adminConsent) || false;
  });
  const [isAuthorized, setIsAuthorized] = useState(() => {
    const auth = JSON.parse(sessionStorage.getItem("isAuthorized"));
    return (auth && auth) || false;
  });
  const [taskOnProgress, setTaskOnProgress] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [loaded, setLoaded] = useState(true);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [deviceMode, setDeviceMode] = useState("windows");
  const [ipadOnboarded, setIpadOnboarded] = useState("checking");
  const { i18n } = useTranslation("global");
  const [ipadOnboardRequested, setIpadOnboardRequested] = useState(false);
  const [windowsPOnboarded, setWindowsPOnboarded] = useState("pending");
  const [ipadPOnboarded, setIpadPOnboarded] = useState("pending");
  const [numiatoStatus, setNumiatoStatus] = useState("1010");
  const [apiToken, setApiToken] = useState(() => {
    const apiInfo = JSON.parse(sessionStorage.getItem("apiAuth")) || false;
    return apiInfo ? apiInfo.token : false;
  });
  Providers.globalProvider.onStateChanged((e) => {
    if (Providers.globalProvider.state !== ProviderState.Loading) {
      const me = JSON.parse(sessionStorage.getItem("me"));
      !me &&
        Providers.me().then((info) => {
          sessionStorage.setItem("me", JSON.stringify(info));
          setIsAuthenticated(
            Providers.globalProvider.state === ProviderState.SignedIn
          );
          sessionStorage.setItem(
            "isAuthenticated",
            Providers.globalProvider.state === ProviderState.SignedIn
          );
        });
    }
  });

  function makeRefresh() {
    setRefresh((prev) => (prev += 1));
  }

  const getTranslations = () => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/settings/translations`)
      .then((translations) => {
        // DE
        i18n.addResourceBundle(
          "de",
          "global",
          { ...translations.data.de },
          true
        );
        // EN
        i18n.addResourceBundle(
          "en",
          "global",
          { ...translations.data.en },
          true
        );
      });
  };

  useEffect(
    () => {
      if (isAuthenticated && (!isAdminConsent || !isAuthorized)) {
        setLoaded(false);
      }
      const me = JSON.parse(sessionStorage.getItem("me"));

      isAuthenticated &&
        Providers.globalProvider.getAccessToken().then((token) => {
          let apiBody = {
            username: me.userPrincipalName,
            password: me.id,
            msToken: token,
          };
          axios
            .post(
              `${process.env.REACT_APP_API_ENDPOINT}/authenticate/`,
              apiBody
            )
            .then((response) => {
              setLoaded(true);
              sessionStorage.setItem("apiAuth", JSON.stringify(response.data));
              setApiToken(response.data.token);
              response.data["adminConsent"]
                ? setIsAdminConsent(true)
                : setIsAdminConsent(false);
              setIsAuthorized(response.data["adminRole"]);
              sessionStorage.setItem(
                "isAuthorized",
                response.data["adminRole"]
              );
              sessionStorage.setItem(
                "PolicyMgmtOnboarded",
                response.data.windowsPOnboarded
              );
              setWindowsPOnboarded(response.data.windowsPOnboarded);
              setIpadPOnboarded(response.data.ipadPOnboarded);
              sessionStorage.setItem("tenantId", response.data.tenantId);
              sessionStorage.setItem("role", response.data.role);
              setIpadOnboarded(response.data.ipadMgmtOnboarded);
              setIpadOnboardRequested(response.data.ipadOnboardRequested);
              setNumiatoStatus(response.data.numiatoStatus);
            });
        });
    },
    [isAuthenticated, isAdminConsent, Providers.globalProvider.state],
    isAuthorized
  );
  useEffect(() => {
    getTranslations();
  }, []);

  const currentURL = window.location.href;

  useEffect(() => {
    if (currentURL.includes("/adminconsent")) {
      setIsOnboarding(true);
    }
  }, [currentURL]);

  if (!isAuthenticated) {
    if (currentURL.includes("/privacy")) {
      return (
        <>
          <HeaderLogo />
          <DataPrivacy />
          <Footer />
        </>
      );
    } else if (currentURL.includes("/terms")) {
      return (
        <>
          <HeaderLogo />
          <TermsAndConditions />
          <Footer />
        </>
      );
    } else {
      return <Login />;
    }
  }

  return (
    <>
      <PortalContext.Provider
        value={{
          deviceMode,
          setDeviceMode,
          ipadOnboarded,
          setIpadOnboarded,
          ipadOnboardRequested,
          setIpadOnboardRequested,
          windowsPOnboarded,
          setWindowsPOnboarded,
          ipadPOnboarded,
          setIpadPOnboarded,
          taskOnProgress,
          setTaskOnProgress,
          apiToken,
          setApiToken,
          numiatoStatus,
          setNumiatoStatus,
        }}
      >
        <Navbar makeRefresh={makeRefresh} />
        {loaded ? (
          isOnboarding ? (
            <FirstOnboarding setIsAuthorized={setIsAuthorized} />
          ) : isAdminConsent ? (
            isAuthorized ? (
              <Routes>
                <Route exact path="/" element={<Home />}></Route>
                <Route
                  exact
                  path="/group"
                  element={
                    <Group refresh={refresh} makeRefresh={makeRefresh} />
                  }
                ></Route>
                <Route
                  exact
                  path="/device"
                  element={<Device refresh={refresh} />}
                ></Route>
                <Route
                  exact
                  path="/device/:id"
                  element={<DeviceDetail refresh={refresh} />}
                ></Route>
                <Route
                  exact
                  path="/adddevice"
                  element={<AddDevice refresh={refresh} />}
                ></Route>
                <Route
                  exact
                  path="/printer"
                  element={<Printer refresh={refresh} />}
                ></Route>
                <Route
                  exact
                  path="/printer/:id"
                  element={<PrinterDetail refresh={refresh} />}
                ></Route>
                <Route
                  exact
                  path="/application"
                  element={<Application refresh={refresh} />}
                ></Route>
                <Route exact path="/policy" element={<Policy />}></Route>
                <Route exact path="/progress" element={<Progress />}></Route>
                <Route
                  exact
                  path="/appstore"
                  element={<Appstore refresh={refresh} />}
                ></Route>
                <Route
                  exact
                  path="/settings"
                  element={<Settings refresh={refresh} />}
                ></Route>
                <Route
                  exact
                  path="/terms"
                  element={<TermsAndConditions />}
                ></Route>
                <Route exact path="/privacy" element={<DataPrivacy />}></Route>
              </Routes>
            ) : (
              <AdminAuthorization />
            ) // ADMIN
          ) : (
            <AdminConsent />
          ) // REQ
        ) : (
          <LoadingHome />
        )}
        <Footer />
      </PortalContext.Provider>
    </>
  );
}

export default App;
