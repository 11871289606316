import React, { useEffect, useState } from "react";
import { isUUID4, useCheckLicenseStatus } from "../tools/utilties";
import { useNavigate, useParams } from "react-router-dom";
import { getApi } from "../tools/axiosInstances";
import { ReactComponent as ArrowBackIcon } from "../components/icons/arrowBack.svg";
import { ReactComponent as UnmanageLogo } from "../components/icons/unmanage.svg";
import { ReactComponent as UninstallLogo } from "../components/icons/uninstall.svg";
import { ReactComponent as InstallLogo } from "../components/icons/install.svg";
import { ReactComponent as RemoveLogo } from "../components/icons/remove.svg";
import PageTitle from "../components/layout/PageTitle";
import PrinterActionButton from "../components/printer/PrinterActionButton";
import PageContent from "../components/layout/PageContent";
import PrinterBasicInfo from "../components/printer/PrinterBasicInfo";
import LoadingTable from "../components/layout/loading/LoadingTable";
import { useTranslation } from "react-i18next";
import { ValidateRole } from "../tools/utilties";
function PrinterDetail({ reload }) {
  const [deviceInfo, setDeviceInfo] = useState(false);
  const { t } = useTranslation("global");
  let { id } = useParams();
  const creating = id == "00000000-0000-0000-0000-000000000000";
  const navigate = useNavigate();
  useEffect(() => {
    if (isUUID4(id) || id == "00000000-0000-0000-0000-000000000000") {
      if (id == "00000000-0000-0000-0000-000000000000") {
        getApi("/printer/templates/", {}).then((info) => {
          setDeviceInfo({
            id: id,
            displayName: "",
            templates: info.data,
            manufacturer: "",
            model: "",
            ipAddress: "",
            templateId: false,
            properties: [],
          });
        });
      } else {
        getApi(`/printer/${id}/`, {}).then((info) => {
          setDeviceInfo(info.data);
        });
      }
    }
  }, [reload, id]);
  useCheckLicenseStatus();
  useEffect(() => {
    ValidateRole();
  }, []);
  return (
    <>
      <PageTitle
        title={
          creating
            ? t("actions.add_printer")
            : deviceInfo && deviceInfo.displayName
        }
        Icon={ArrowBackIcon}
        iconOnClick={() => navigate(-1)}
      >
        {!creating && (
          <PrinterActionButton
            kind="del"
            target="pri"
            title={"pages.application.delete"}
            selected={[deviceInfo]}
            Icon={RemoveLogo}
          />
        )}
      </PageTitle>
      <PageContent>
        <div className="w-3/5">
          {deviceInfo ? (
            <PrinterBasicInfo deviceInfo={deviceInfo} />
          ) : (
            <LoadingTable />
          )}
        </div>
      </PageContent>
    </>
  );
}

export default PrinterDetail;
