import React, { useState, useMemo, useEffect, useContext } from "react";
import {
  createColumnHelper,
  getSortedRowModel,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import IndeterminateCheckbox from "../layout/IndeterminateCheckbox";
import SortingIcon from "../layout/SortingIcon";
import { rankItem } from "@tanstack/match-sorter-utils";
import ApplicationLogo from "./ApplicationLogo";
import { PortalContext } from "../../PortalContext";
function ApplicationTableV2({ appData, setSelected, reload, clear }) {
  const { t } = useTranslation("global");
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({
    type: false,
  });
  const { deviceMode } = useContext(PortalContext);
  const [rowSelection, setRowSelection] = useState({});
  const [lastSelected, setLastSelected] = useState([]);
  const data = useMemo(() => [...appData], [appData, reload]);
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("select", {
      header: ({ table }) => (
        <IndeterminateCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      className: "w-16",
      classNameHeader: "w-16",
      cell: ({ row }) => (
        <IndeterminateCheckbox
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect}
          indeterminate={row.getIsSomeSelected()}
          onChange={(e) => {
            handleSelection(e, row);
          }}
        />
      ),
      enableResizing: false,
      enableSorting: false,
    }),
    columnHelper.accessor("logo", {
      header: () => "",
      cell: (info) => <ApplicationLogo logoUrl={info.getValue()} />,
      enableSorting: false,
      enableResizing: false,
    }),
    columnHelper.accessor("displayName", {
      header: () => (
        <span>{t("pages.application.table.application_name")}</span>
      ),
      cell: (info) => info.getValue(),
      className: "text-left cursor-pointer truncate px-2",
      classNameHeader: "cursor-pointer",
      size: 450,
    }),
    columnHelper.accessor("status", {
      header: () => <span>{t("pages.application.table.status")}</span>,
      cell: (info) => (
        <span>
          {t(`pages.application.table.${info.getValue()}`, info.getValue())}
        </span>
      ),
    }),
    columnHelper.accessor("type"),
  ];
  useEffect(() => {
    setSelected(table.getSelectedRowModel().flatRows);
  }, [rowSelection]);
  useEffect(() => {
    table.getColumn("type").setFilterValue(deviceMode);
    table.setRowSelection({});
  }, [deviceMode]);
  useEffect(() => {
    table.resetRowSelection(true);
  }, [clear]);
  const handleSelection = (e, row) => {
    setLastSelected(row);
    if (e.nativeEvent.shiftKey) {
      const sortedRows = table.getSortedRowModel().rows;
      const lastSelectedIndex = sortedRows.findIndex(
        (r) => r.id == lastSelected.id
      );
      const clickedIndex = sortedRows.findIndex((r) => r.id == row.id);

      if (lastSelectedIndex < clickedIndex) {
        for (let i = lastSelectedIndex; i <= clickedIndex; i++) {
          const element = sortedRows[i];
          !element.getIsSelected() && element.toggleSelected(true);
        }
      } else {
        for (let i = clickedIndex; i <= lastSelectedIndex; i++) {
          const element = sortedRows[i];
          !element.getIsSelected() && element.toggleSelected(true);
        }
      }
    } else {
      row.toggleSelected();
    }
  };

  const fuzzyFilter = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
      itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      globalFilter,
      rowSelection,
      sorting,
      columnVisibility,
      columnFilters,
    },
    enableColumnResizing: true,
    enableColumnFilters: true,
    columnResizeMode: "onChange",
    enableRowSelection: true,
    enableGlobalFilter: true,
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    onglobalFilterFn: fuzzyFilter,
    GlobalFilterChange: setGlobalFilter,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });
  return (
    <>
      <div className="overflow-auto h-[calc(100%-4rem)]">
        <table
          className="text-center  mt-2"
          {...{ style: { width: table.getCenterTotalSize() } }}
        >
          <thead className="m-2 top-0 sticky bg-white">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={`py-4 truncate text-fgray-300 font-medium text-left ${
                      header.column.columnDef.classNameHeader &&
                      flexRender(
                        header.column.columnDef.classNameHeader,
                        header.getContext()
                      )
                    } `}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div className="flex justify-end">
                      <div className={`flex px-4  w-full `}>
                        <p
                          className={`transition hover:text-black ${
                            header.column.getIsSorted() && " text-black "
                          } `}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </p>
                        {header.column.getCanSort() &&
                          (header.column.getIsSorted() ? (
                            header.column.getIsSorted() == "asc" ? (
                              <SortingIcon status={"asc"} />
                            ) : (
                              <SortingIcon status={"desc"} />
                            )
                          ) : (
                            <SortingIcon status={"plain"} />
                          ))}
                      </div>
                      {/* Resizing */}
                      {header.column.getCanResize() && (
                        <div
                          onMouseDown={header.getResizeHandler()}
                          onTouchStart={header.getResizeHandler()}
                          className={`h-10 border-r-2 select-none  touch-none align-middle absolute  cursor-col-resize hover:border-r-4 hover:border-fgray-300 ${
                            header.column.getIsResizing()
                              ? " border-fgray-400 "
                              : " "
                          }`}
                        ></div>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className={
                    `border-y-2 font-normal  hover:font-medium focus:bg-forange-400 ` +
                    (row.getIsSelected()
                      ? `bg-forange-200 font-medium`
                      : `hover:bg-forange-100`)
                  }
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={
                        cell.column.getCanResize()
                          ? { width: cell.column.getSize() }
                          : {}
                      }
                      className={`py-5 font-xs px-4 ${
                        cell.column.columnDef.className &&
                        flexRender(
                          cell.column.columnDef.className,
                          cell.getContext()
                        )
                      }`}
                    >
                      <p
                        className="truncate  text-left"
                        style={
                          cell.column.getCanResize()
                            ? { width: cell.column.getSize() }
                            : {}
                        }
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </p>
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  className="text-fgray-400 text-xl p-5 w-[885px]"
                  colSpan="5"
                >
                  {t("pages.device.table.no_applications")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default ApplicationTableV2;
