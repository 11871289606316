import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PortalContext } from "../../PortalContext";
function SettingsSidebar({ children }) {
  return (
    <>
      <nav className="mr-5 w-64 mb-12 ">
        <ul className="nav flex flex-col w-full">
          {children}
        </ul>
      </nav>
    </>
  );
}

export default SettingsSidebar;

export function SettingsSidebarItem({ children, handleOnClick, active }) {
  const { t } = useTranslation("global");
  return (
    <>
      <li
        className={
          `rounded nav-item px-4 py-3 mx-4 list-none items-center cursor-pointer  hover:font-medium font-normal active:rounded justify-between flex ` +
          (active
            ? "bg-forange-400 text-white font-medium"
            : " hover:text-forange-400 ")
        }
        onClick={handleOnClick}
      >
        <p>{children}</p>
      </li>
    </>
  );
}
