import {
  createColumnHelper,
  getSortedRowModel,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import IndeterminateCheckbox from "../layout/IndeterminateCheckbox";
import {
  fuzzyFilter,
  muxFilter,
} from "../device/DeviceTableFilterFn";
import SortingIcon from "../layout/SortingIcon";
function PrinterTable({printerData,setSelected,filter,reload,clear,showColumns,showFilter}) {
  const [globalFilter, setGlobalFilter] = useState("") //Room/Category filter
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({

  });
  const [rowSelection, setRowSelection] = useState({});
  const [lastSelected, setLastSelected] = useState([]);
  const [showFilterBar, setShowFilterBar] = useState(false);
  const [sorting, setSorting] = useState([]);
  const { t } = useTranslation("global");
  const navigate = useNavigate();
  const data = useMemo(() => [...printerData], [filter, printerData, reload]);
  const clearColumnFilters = () => setColumnFilters([]);
  const columnHelper = createColumnHelper();

  const handleTableHead = () => {
    return t("table.header.printer", {
      total: data.length,
      count: Object.keys(rowSelection).length,
    });
  };
  const handleSelection = (e, row) => {
    setLastSelected(row);
    if (e.nativeEvent.shiftKey) {
      const sortedRows = table.getSortedRowModel().rows;
      const lastSelectedIndex = sortedRows.findIndex(
        (r) => r.id == lastSelected.id
      );
      const clickedIndex = sortedRows.findIndex((r) => r.id == row.id);

      if (lastSelectedIndex < clickedIndex) {
        for (let i = lastSelectedIndex; i <= clickedIndex; i++) {
          const element = sortedRows[i];
          !element.getIsSelected() && element.toggleSelected(true);
        }
      } else {
        for (let i = clickedIndex; i <= lastSelectedIndex; i++) {
          const element = sortedRows[i];
          !element.getIsSelected() && element.toggleSelected(true);
        }
      }
    } else {
      row.toggleSelected();
    }
  };

  const columns = [
    columnHelper.accessor("select", {
      header: ({ table }) => (
        <IndeterminateCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      className: "w-16",
      classNameHeader: "w-16",
      cell: ({ row }) => (
        <IndeterminateCheckbox
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect}
          indeterminate={row.getIsSomeSelected()}
          onChange={(e) => {
            handleSelection(e, row);
          }}
        />
      ),
      enableResizing: false,
      enableSorting: false,
      enableColumnFilter: false,
    }),

    columnHelper.accessor("displayName", {
      header: () => <span>{handleTableHead()}</span>,
      cell: (info) => info.getValue(),
      className: "text-left cursor-pointer hover:text-ellipsis",
      classNameHeader: "cursor-pointer",
      size: 210,
      minSize: 150,
      label: t("pages.printer.table.printer_name"),
      filterFn: muxFilter,
    }),
    columnHelper.accessor("manufacturer", {
      header: () => <span>{t("properties.manufacturer")}</span>,
      className: "cursor-pointer hover:text-ellipsis",
      classNameHeader: "cursor-pointer",
      cell: (info) => info.getValue(),
      size: 160,
      minSize: 150,
      label: t("pages.device_detail.manufacturer"),
      canHide: true,
    }),
    columnHelper.accessor("ipAddress", {
      header: () => <span>{t("properties.ip_address")}</span>,
      className: "cursor-pointer",
      classNameHeader: "cursor-pointer",
      size: 160,
      minSize: 150,
      enableColumnFilter: true,
      label: t("properties.ip_address"),
      canHide: true,
    }),
    columnHelper.accessor("status", {
      header: () => <span>{t("properties.status")}</span>,
      cell: (info) => (
        <span>
          {t(`status.${info.getValue()}`, info.getValue())}
        </span>
      ),
    }),
  ];

  useEffect(() => {
    let tableColumn = {
    };
    showColumns.map((col) => {
      tableColumn[col.id] = col.visible;
    });
    setColumnVisibility(tableColumn);
  }, [showColumns]);

  useEffect(() => {
    setShowFilterBar(showFilter);
    if (!showFilter) {
      clearColumnFilters();
    }
  }, [showFilter]);
  useEffect(() => {
    table.resetRowSelection(true);
  }, [clear]);
  // useEffect(() => {
  //   // Category/Room/Global filtering
  //   if (filter == "99999999-9999-9999-9999-999999999999") {
  //     setGlobalFilter("");
  //   } else {
  //     setGlobalFilter(filter);
  //   }
  // }, [filter]);
  useEffect(() => {
    setSelected(table.getSelectedRowModel().flatRows);
  }, [rowSelection]);
  const handleColumnFilterChange = (e) => {
    setColumnFilters(e);
    table.setRowSelection({});
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      globalFilter,
      rowSelection,
      sorting,
      columnVisibility,
      columnFilters,
    },
    enableColumnResizing: true,
    enableColumnFilters: true,
    columnResizeMode: "onChange",
    enableRowSelection: true,
    enableGlobalFilter: true,
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: handleColumnFilterChange,
    onSortingChange: setSorting,
    onglobalFilterFn: fuzzyFilter,
    GlobalFilterChange: setGlobalFilter,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });


  return (
   <div className="w-full">
   <div className="overflow-auto h-[calc(100%-4rem)] ">
        <table
          className="text-center  mt-2"
          {...{ style: { width: table.getCenterTotalSize() } }}
        >
          <thead className="m-2 top-0 sticky bg-white">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={` truncate text-fgray-300 text-left  ${
                      header.column.columnDef.classNameHeader &&
                      flexRender(
                        header.column.columnDef.classNameHeader,
                        header.getContext()
                      )
                    } `}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div className="flex justify-end">
                      <div className={`flex px-4  w-full align-middle `}>
                        <p
                          className={`transition hover:text-black text-xs font-normal my-auto  ${
                            header.column.getIsSorted() && " text-black "
                          } `}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </p>
                        {header.column.getCanSort() &&
                          (header.column.getIsSorted() ? (
                            header.column.getIsSorted() == "asc" ? (
                              <SortingIcon status={"asc"} />
                            ) : (
                              <SortingIcon status={"desc"} />
                            )
                          ) : (
                            <SortingIcon status={"plain"} />
                          ))}
                      </div>
                      {/* Resizing */}
                      {header.column.getCanResize() && (
                        <div
                          onMouseDown={header.getResizeHandler()}
                          onTouchStart={header.getResizeHandler()}
                          className={`h-6 border-r-2 select-none  touch-none align-middle   cursor-col-resize hover:border-r-4 hover:border-fgray-300 ${
                            header.column.getIsResizing()
                              ? " border-fgray-400 "
                              : " "
                          }`}
                        ></div>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className={
                    `border-b-2  hover:font-medium font-normal focus:bg-forange-400 ` +
                    (row.getIsSelected()
                      ? `bg-forange-200 font-medium`
                      : `hover:bg-forange-100`)
                  }
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={
                        cell.column.getCanResize()
                          ? { width: cell.column.getSize() }
                          : {}
                      }
                      className={`py-5 text-base font-normal px-4 ${
                        cell.column.columnDef.className &&
                        flexRender(
                          cell.column.columnDef.className,
                          cell.getContext()
                        )
                      }`}
                      onClick={
                        cell.column.id == "select"
                          ? null
                          : () => navigate(`/printer/${row.original.id}`)
                      }
                    >
                      <p
                        className="truncate  text-left"
                        style={
                          cell.column.getCanResize()
                            ? { width: cell.column.getSize() }
                            : {}
                        }
                      >
                        {cell.getValue() || cell.column.id === "select"
                          ? flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )
                          : "-"}
                      </p>
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  className="text-fgray-400 text-xl p-5 w-[885px]"
                  colSpan="5"
                >
                  {t("pages.device.table.no_devices")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {showFilterBar && <div className="h-28"></div>}
      </div> 
   </div>
  )
}

export default PrinterTable